<template>
	<div>
		<Template :title="$t('admin.rightAssign')">
			<section class="p-5 py-4">
				<Back />

				<div class="row mt-2">
					<div class="col-xl-9">
						<div class="">
							<div
								class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
							>
								{{ $t('admin.rightOf') }}: {{ coadmin.lastname }}
								{{ coadmin.firstname }}
							</div>
							<div class="mt-4">
								<table
									class="table table-bordered table-striped"
									style="width: 100% !important"
								>
									<thead class="thead-dark">
										<tr>
											<th></th>
											<th
												class="text-center"
												v-for="(operation, index) in operations"
												:key="index"
											>
												{{ operation.text }}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(menu, index) in menus" :key="index">
											<td>{{ menu.text }}</td>
											<td
												class="text-center"
												v-for="(operation, i) in operations"
												:key="i"
											>
												<input
													role="button"
													:checked="findRigth(menu.key, operation.key)"
													@change="
														toggleRight(menu, operation, $event.target.checked)
													"
													:id="`checkbox_${menu.key}_${operation.key}`"
													type="checkbox"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="action d-flex justify-content-center">
								<button
									@click="updateCoAdmin()"
									class="col-6 btn bg-primary text-white-"
								>
									<span class="text-white">{{$t('admin.assign')}}</span>
								</button>
							</div>
						</div>
					</div>
					<div class="col-xl-3">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{ $t('global.advice') }}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
							v-html="$t('global.advice_action')"
						></div>
					</div>
				</div>

				<div class="row d-flex justify-content-center"></div>
			</section>
		</Template>
	</div>
</template>

<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import Back from '@/components/Back.vue';
// import SpinnerMe from '@/components/SpinnerMe.vue';

export default {
	components: { Template, Back },

	data() {
		return {
			id: 0,

			coadmin: { rights: [] },

			menus: [],
			operations: [],

			newRights: [],
			rightsToAttrs: [],
		};
	},

	created() {
		this.id = this.$route.params.id;
		this.getCoadmin();
		this.getCoAdminMenus();
		this.getCoAdminOperations();
	},

	methods: {
		toggleCheckbox(menu, operation) {
			const checkboxId = `checkbox_${menu.key}_${operation.key}`;
			const checkbox = document.getElementById(checkboxId);

			if (checkbox) {
				checkbox.checked = !checkbox.checked;
				this.toggleRight(menu, operation, checkbox.checked);
			}
		},
		findRigth(menuKey, opKey) {
			var rights = [];

			const co = this.coadmin;

			rights = co.rights;

			var thisRight = rights.find((r) => r.menu.key == menuKey);

			if (thisRight) {
				if (thisRight.operations.find((o) => o.key == opKey)) {
					return true;
				}
			}

			return false;
		},

		toggleRight(menu, operation, checked) {
			var newR = this.newRights;

			delete operation.createdAt;
			delete operation.updatedAt;
			delete operation.id;

			delete menu.createdAt;
			delete menu.updatedAt;
			delete menu.id;

			var rExist = newR.find((r) => r.menu.key == menu.key);

			if (checked) {
				if (rExist) {
					rExist.operations.push(operation);
				} else {
					const r = {
						menu: menu,
						operations: [operation],
					};

					newR.push(r);
				}
			} else {
				const index = rExist.operations.findIndex(
					(o) => o.key == operation.key
				);

				rExist.operations = rExist.operations.filter((o, i) => i !== index);

				if (rExist.operations.length == 0) {
					const i = newR.findIndex((r) => r.menu.key == menu.key);

					newR = newR.filter((r, id) => id !== i);
				}
			}

			this.rightsToAttrs = newR;
		},

		updateCoAdmin() {
			this.$isLoading(true);
			this.loading = true;

			const data = {
				id: this.coadmin.id,
				rights: this.rightsToAttrs,
			};

			fetch(
				Helper.route('admin/management/coadmin/manage-rights'),
				Helper.requestOptionsJSON('POST', data)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					this.$isLoading(false);

					if (data.status) {
						Helper.notification('success', data.message);
					} else {
						Helper.notification('error', data.message);
					}
				})
				.catch((error) => {
					this.loading = false;
					this.$isLoading(false);
					console.log(error);
				});
		},

		getCoAdminMenus() {
			this.$isLoading(true);
			this.loading = true;
			fetch(
				Helper.route('admin/management/menu/all?sortBy=text&order=ASC'),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					this.$isLoading(false);
					if (data.data) {
						this.menus = data.data;
					}
					console.log(data);
				})
				.catch((error) => {
					this.loading = false;
					this.$isLoading(false);
					console.log(error);
				});
		},

		getCoAdminOperations() {
			this.$isLoading(true);
			this.loading = true;
			fetch(
				Helper.route('admin/management/operation/all?sortBy=id&order=ASC'),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					this.$isLoading(false);
					if (data.data) {
						this.operations = data.data;
					}
				})
				.catch((error) => {
					this.loading = false;
					this.$isLoading(false);
					console.log(error);
				});
		},

		getCoadmin() {
			this.$isLoading(true);
			this.loading = true;
			fetch(
				Helper.route('admin/management/coadmin/' + this.id),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					this.$isLoading(false);
					if (data.data) {
						this.coadmin = data.data;

						for (const r of this.coadmin.rights) {
							this.newRights.push({
								menu: r.menu,
								operations: r.operations,
							});

							// const ops = [];

							// for (const op of r.operations) {
							// 	ops.push({
							// 		key: op.key,
							// 		text: op.text,
							// 	});
							// }

							// this.rightsToAttrs.push({
							// 	id: this.coadmin.id,
							// 	rights: [
							// 		{
							// 			menu: {
							// 				key: r.menu.key,
							// 				text: r.menu.text,
							// 			},
							// 			operations: ops,
							// 		},
							// 	],
							// });
						}
					}
				})
				.catch((error) => {
					this.loading = false;
					this.$isLoading(false);
					console.log(error);
				});
		},
	},
};
</script>
