<template>
	<div>
		<Template :title="$t('topbar.my_subscriptions')">
			<div class="row px-4 m-0 g-4">
				<div class="col-xl-9">
					<!-- <h5 class="text-black- fw-800 mt-1" v-if="total_spent !== 0">
						{{ $t('subscription.total_spent') }}
						<b class="text-primary-"
							>{{ $filters.numberWithSpaces(total_spent) }} XOF</b
						>
					</h5> -->

					<div class="m-0">
						<div class="table table-responsive mt-3">
							<table
								class="table align-middle mb-0"
								style="width: 100% !important"
							>
								<thead class="bg-primary-">
									<tr class="text-5 text-white-">
										<th class="fw-800 text-white-">#</th>
										<th class="fw-800 text-white-">
											{{ $t('subscription.suscribe_plan') }}
										</th>
										<th class="fw-800 text-white-">{{ $t('global.type') }}</th>
										<th class="fw-800 text-white-">
											{{ $t('global.amount') }}
										</th>
										<th class="fw-800 text-center text-white-">
											{{ $t('global.status') }}
										</th>
										<th class="fw-800 text-white-">
											{{ $t('global.start_date') }}
										</th>
										<th class="fw-800 text-white-">
											{{ $t('global.end_date') }}
										</th>
									</tr>
								</thead>
								<tbody v-if="!loading && subscriptions.length != 0">
									<tr
										class="text-5 text-white"
										v-for="(subscription, i) in subscriptions"
										:key="subscription.id"
										v-show="show(i)"
									>
										<td class="text-black- text-6 fw-bold">{{ i + 1 }}</td>
										<td class="text-black- text-6 fw-bold">
											{{ subscription.plan.name }}
										</td>
										<td class="text-black- text-6 fw-bold">
											{{
												subscription.type == 'monthly'
													? $t('global.monthly')
													: $t('global.annual')
											}}
										</td>
										<td class="text-black- text-6 fw-bold">
											{{ $filters.numberWithSpaces(subscription?.price) }} XOF
										</td>
										<td class="text-center">
											<span v-if="subscription.status == 'canceled'"
												class="button text-6 bg-red- p-1 text-white px-3"
												>{{ 'Annulé' }}</span
											>
											<span v-else-if="subscription.status == 'expired'"
												class="button text-6 bg-red- p-1 text-white px-3"
												>{{ 'Expiré' }}</span
											>
											<span
												v-else
												class="button text-6 bg-green- p-1 text-white px-3"
												>{{ 'Actif' }}</span
											>
										</td>
										<td class="text-6">{{ $filters.convertDate(subscription.startsAt) }}</td>
										<td class="text-6">{{ $filters.convertDate(subscription.expiresAt) }}</td>
									</tr>
								</tbody>
								<tbody v-if="!loading && subscriptions.length == 0">
									<tr>
										<td colspan="5" class="text-center">
											{{ $t('global.none_element') }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<nav aria-label="Page navigation" class="mt-3"
						v-if="subscriptions.length > perPage">
						<ul class="pagination justify-content-center">
							<li class="page-item" @click="previous">
								<a class="page-link" href="#" aria-label="Previous">
									<span aria-hidden="true">&laquo;</span>
								</a>
							</li>

							<li class="page-item" @click="previous">
								<a class="page-link" href="#">{{$t('global.previous')}}</a>
							</li>
							<small class="mx-3 mt-2"
								>{{
									Math.ceil(subscriptions.length / perPage) > 0 ? page : 0
								}}
								/ {{ Math.ceil(subscriptions.length / perPage) }}</small
							>
							<li class="page-item" @click="next">
								<a class="page-link" href="#">{{$t('global.next')}}</a>
							</li>

							<li class="page-item" @click="next">
								<a class="page-link" href="#" aria-label="Next">
									<span aria-hidden="true">&raquo;</span>
								</a>
							</li>
						</ul>
					</nav>

				</div>
				<div class="col-xl-3 mt-4">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						v-html="$t('suscribe.subscription_advice_text')"
					></div>
				</div>
			</div>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Subscription',
	components: { Template },
	data() {
		return {
			loading: true,
			total_spent: 0,
			subscriptions: [],
			page: 1,
			perPage: 10,
		};
	},
	methods: {
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.subscriptions.length) {
				this.page++;
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		loadList() {
			this.$isLoading(true);
			this.loading = true;
			fetch(Helper.route('admin/subscription/all'), Helper.requestOptions())
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					this.$isLoading(false);
					if (data.data) {
						this.subscriptions = data.data.reverse();
						this.loadSpent();
					}
					console.log(data);
				})
				.catch((error) => {
					this.loading = false;
					this.$isLoading(false);
					console.log(error);
				});
		},
		loadSpent() {
			this.subscriptions.forEach((subscription) => {
				this.total_spent += subscription?.price ?? 0;
			});
		},
	},
	created() {
		this.loadList();
	},
};
</script>
<style></style>
