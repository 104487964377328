<template>
	<div>
		<Template title="null">
			<div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<router-link
					to="/offers"
					class="bg-light-hight- ms-2 text-6 top-item"
					>{{ $t('candidate.offers.job_offers') }}</router-link
				>
				<router-link
					to="/candidates-profiles"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{ $t('candidate.offers.employee_profile') }}</router-link
				>
				<!-- <router-link
					to="/test-brainstorming"
					class="bg-light-hight- ms-4 text-6 top-item"
					>{{$t('suscribe.brainstorming_test')}}</router-link
				> -->
			</div>




			<!-- Info modal -->
			<div
				class="modal fade"
				id="infoModal"
				data-mdb-backdrop="static"
				data-mdb-keyboard="false"
				tabindex="-1"
				aria-labelledby="infoModalLabel"
				aria-hidden="true"
				>
				<div class="modal-xl modal-dialog modal-dialog-centered ">
					<div class="modal-content rounded-0">
						<div class="modal-body">
							<h6 class="text-center text-red-">
								{{ $t('candidate.offers.offer_detail') }}
							</h6>
							<hr />
							<div class="" v-if="offer_selected!=null">
								<div class="row align-items-center">
									<p class="col-4">{{$t('candidate.offers.task_to_complete')}}</p>
									<div v-html="offer_selected.tasks" class="mt-2 border rounded col-8"></div>
								</div>
								<div class="row align-items-center mt-3">
									<p class="col-4">{{$t('candidate.offers.execution_place')}}</p>
									<div class=" col-8 border rounded ">	
										{{ offer_selected.location }}
									</div>
								</div>
								<div class="row align-items-center mt-3">
									<p class="col-4">{{$t('candidate.offers.required_docs')}}</p>
									<div class=" col-8 border rounded " v-html="offer_selected.requiredDocument">	
									
									</div>
								</div>
								<div class="row align-items-center mt-3">
									<p class="col-4">{{ $t('global.on_line') }}</p>
									<div class=" col-8 border rounded ">	
										{{ $filters.convertDate(offer_selected.createdAt) }}
									</div>
								</div>
								<div class="row align-items-center mt-3">
									<p class="col-4">{{$t('global.contact')}}</p>
									<div class=" col-8 border rounded ">	
										{{ offer_selected.contact }}
									</div>
								</div>
							</div>
							<div class="row mt-2">
								<div class="col text-center">
									<button
										type="button"
										class="border bg-light px-3 text-5 rounded py-1"
										data-mdb-dismiss="modal"
									>
										{{$t('global.close')}}
									</button>
								</div>
								
							</div>
						</div>
					</div>
				</div>

				<div class="modal-backdrop fade show" style="z-index: -100;"></div>
			</div>
		




			<section class="row px-4 m-0 g-4 mt-1">
				<!-- Suppression -->
				<div
					class="modal fade"
					id="deleteModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-red-" v-html="$t('global.deletion_warning')">
									
								</h6>
								<hr />
								<p class="text-5" v-show="!deleted" v-html="$t('candidate.offers.warning_text')">
									
								</p>
								<p class="text-5 text-center" v-show="deleted">
									{{ $t('candidate.offers.deleted_successfully') }}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{ $t('global.close') }}
										</button>
									</div>
									<div
										class="col text-center"
										v-show="!deleted && selectedList.length !== 0"
									>
										<button
											type="button"
											class="bg-red- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
											@click="manage('deleted')"
										>
											{{ $t('global.delete') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-9">
					<div v-if="!loading && bests.length != 0">
						<div class="row">
							<div
								class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
							>
								{{ $t('candidate.offers.in_news') }}
							</div>

							<div class="row mt-1 g-2 mb-4">
								<div
									v-for="(offer, index) in bests"
									:key="index"
									class="col-md-3"
								>
									<div class="bg-light-hight-1 text-center rounded">
										<div class="text-center p-2">
											<div class="">
												<i class="uil uil-award offer_icon fs-1"></i>
											</div>
											<div class="text-5 text-black-">
												<router-link
													:to="{
														name: 'offer',
														params: { id: offer.id, slug: offer.slug },
													}"
												>
													{{ offer.post }}
												</router-link>
											</div>
										</div>
										<div class="bg-second- text-white rounded p-2 text-6">
											<span
												v-if="offer.status === 'closed'"
												class="bg-red- text-white offer_status p-1"
											>
												{{ $t('candidate.offers.fenced') }}</span
											>
											<span
												v-else-if="offer.status === 'canceled'"
												class="bg-orange- text-white offer_status p-1"
											>
												{{ $t('candidate.offers.canceled') }}</span
											>
											<span
												v-else-if="offer.status === 'suspended'"
												class="bg-light text-dark offer_status p-1"
											>
												{{ $t('candidate.offers.suspended') }}</span
											>
											<span
												v-else
												class="bg-green- text-white offer_status p-1"
											>
												{{ $t('global.pending') }}</span
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="hstack">
							<router-link
								to="/offer/create"
								class="bg-second- rounded ms-auto px-3 p-1"
							>
								<i class="uil uil-plus text-white"></i>
								<span class="ms-2 text-6 text-white">{{
									$t('candidate.offers.post_offer')
								}}</span>
							</router-link>
						</div>

						<div class="hstack mt-4 p-2 px-4 rounded shadow-1 bg-white">
							<input type="checkbox" @click="check(0, true)" />
							<!-- <i class="uil uil-archive ms-3 fs-6"  type="button" data-mdb-toggle="modal" data-mdb-target="#archiveModal" @click="archived = false"></i> -->
							<i
								class="uil uil-trash fs-6 ms-3"
								type="button"
								data-mdb-toggle="modal"
								data-mdb-target="#deleteModal"
								@click="deleted = false"
							></i>

							<div class="hstack ms-auto">
								<label for="" class="text-5 me-2">{{
									$t('global.filter')
								}}</label>
								<select class="form-control text-6" v-model="orderBy">
									<option value="id">
										{{ $t('global.order_of_addition') }}
									</option>
									<option value="post">
										{{ $t('candidate.offers.position') }}
									</option>
									<option value="profile">
										{{ $t('candidate.offers.profile') }}
									</option>
									<option value="contract">
										{{ $t('candidate.offers.contract_type') }}
									</option>
									<option value="placeNumber">
										{{ $t('candidate.offers.place_number') }}
									</option>
									<option value="closingDate">
										{{ $t('candidate.offers.closing_date') }}
									</option>
									<option value="status">{{ $t('global.status') }}</option>
								</select>

								<label for="" class="text-5 ms-2 me-2">{{
									$t('global.order')
								}}</label>
								<select class="form-control text-6" v-model="order">
									<option value="ASC">ASC</option>
									<option value="DESC">DESC</option>
								</select>
							</div>

							<div class="ms-auto hstack">
								<input type="search" v-model="searchText" class="form-control mx-2 w-40" :placeholder="$t('tools.search')">
								<i
									class="uil uil-arrow-circle-left fs-5"
									type="button"
									@click="previous"
								></i>
								<i
									class="uil uil-arrow-circle-right fs-5 ms-3"
									type="button"
									@click="next"
								></i>
								<div class="hstack ms-2">
									<i
										class="uil uil-apps fs-6 p-1 px-3 rounded"
										:class="
											store.disposition == 'grid'
												? 'bg-black- text-white-'
												: 'bg-white- text-black-'
										"
										@click="store.setDisposition('grid')"
										type="button"
									></i>
									<i
										class="uil uil-list-ul fs-6 ms-1 p-1 px-3 rounded"
										:class="
											store.disposition == 'list'
												? 'bg-black- text-white-'
												: 'bg-white- text-black-'
										"
										@click="store.setDisposition('list')"
										type="button"
									></i>
								</div>
							</div>
						</div>

						<LoadingMain :state="loading" />

						<div class="p-0" v-if="!loading && offers.length != 0">
							<div class="table-responsive" v-if="store.disposition == 'list'">
								<table
									class="table employees table-hover table-striped mb-0"
									style="width: 100% !important"
								>
									<thead class="bg-primary-">
										<tr class="text-6 text-white">
											<th></th>
											<th>{{ $t('candidate.offers.position') }}</th>
											<th>{{ $t('global.profile_search') }}</th>
											<th>{{ $t('global.type') }}</th>
											<th>{{ $t('global.number') }}</th>
											<th>{{ $t('global.fenced') }}</th>
											<th class="text-center">{{ $t('global.status') }}</th>
											<th class="text-center">{{ $t('global.details') }}</th>
											<th></th>
										</tr>
									</thead>
									<tbody class="bg-alt-">
										<tr
											v-for="(offer, index) in offers"
											:key="offer.id"
											v-show="show(index)"
											class="text-6 text-black"
										>
											<td>
												<input
													type="checkbox"
													:checked="isSelected(offer.id)"
													@click="check(offer.id)"
												/>
											</td>
											<td>
												<router-link
													:to="{
														name: 'offer',
														params: { id: offer.id, slug: offer.slug },
													}"
												>
													{{ offer.post }}
												</router-link>
											</td>
											<td>{{ offer.profile }}</td>
											<td>{{ offer.contract }}</td>
											<td class="text-center">{{ offer.placeNumber }}</td>
											<td>
												{{
													$filters.convertDate(
														new Date(offer.closingDate).toJSON()
													)
												}}
											</td>
											<td class="text-center" v-if="offer.status == 'closed'">
												<div class="bg-red- text-white rounded-pill px-3 p-1">
													{{ $t('global.fenced') }}
												</div>
											</td>
											<td
												class="text-center"
												v-else-if="offer.status == 'canceled'"
											>
												<div
													class="bg-orange- text-white rounded-pill px-3 p-1"
												>
													{{ $t('global.canceled') }}
												</div>
											</td>
											<td
												class="text-center"
												v-else-if="offer.status === 'suspended'"
											>
												<div class="bg-light text-dark rounded-pill px-3 p-1">
													{{ $t('global.suspended') }}
												</div>
											</td>
											<td class="text-center" v-else>
												<div class="bg-green- text-white rounded-pill px-3 p-1">
													{{ $t('global.pending') }}
												</div>
											</td>
											<td class="text-center">
												<!-- <router-link
													:to="{
														name: 'offer',
														params: { id: offer.id, slug: offer.slug },
													}"
												>
													<i class="uil uil-info-circle text-white fs-6"></i>
												</router-link> -->
												<i class="uil uil-info-circle text-white fs-6" type="button" data-mdb-toggle="modal"
												data-mdb-target="#infoModal"  @click="offer_selected = offer"></i>
											</td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>

							<div v-else class="row mt-1 g-2">
								<div
									v-for="(offer, index) in offers"
									:key="index"
									class="col-md-3"
								>
									<div class="bg-light-hight-1 text-center rounded">
										<div class="text-center p-2">
											<div class="">
												<i class="uil uil-award offer_icon fs-1"></i>
											</div>
											<div class="text-5">
												<input
													type="checkbox"
													class="me-1"
													:checked="isSelected(offer.id)"
													@click="check(offer.id)"
												/>
												<router-link
													:to="{
														name: 'offer',
														params: { id: offer.id, slug: offer.slug },
													}"
												>
													{{ offer.post }}
												</router-link>
											</div>
										</div>
										<div class="bg-second- text-white rounded p-2 text-6">
											<span
												v-if="offer.status === 'closed'"
												class="bg-red- text-white offer_status p-1"
											>
												{{ $t('candidate.offers.fenced') }}</span
											>
											<span
												v-else-if="offer.status === 'canceled'"
												class="bg-orange- text-white offer_status p-1"
											>
												{{ $t('candidate.offers.canceled') }}</span
											>
											<span
												v-else-if="offer.status === 'suspended'"
												class="bg-light text-dark offer_status p-1"
											>
												{{ $t('candidate.offers.suspended') }}</span
											>
											<span
												v-else
												class="bg-green- text-white offer_status p-1"
											>
												{{ $t('global.pending') }}</span
											>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							class="py-4 text-center text-black-"
							v-if="!loading && offers.length == 0"
						>
							{{ $t('global.no_data') }}
						</div>
					</div>
				</div>
				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						v-html="$t('candidate.offers.index_advice')"
					></div>
					<router-link to="/offer/create"
						><button class="button bg-alt- btn-sm w-100 mt-1 p-1">
							{{ $t('candidate.offers.post_offer_now') }}
						</button>
					</router-link>

					<div class="" style="margin-top: 78px">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{ $t('candidate.offers.today_summary') }}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						>
							<div class="row px-3" v-if="stats.jobStatus">
								<div class="col-6 py-1 text-start">
									{{ $t('candidate.offers.offer_nomber') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.offers, false, false) }}
								</div>
								<div class="col-6 py-1 text-start">
									{{ $t('global.pending') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.jobStatus[0].number, false) }}
								</div>
								<div class="col-6 py-1 text-start">
									{{ $t('candidate.offers.suspended') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.jobStatus[1].number, false) }}
								</div>
								<div class="col-6 py-1 text-start">
									{{ $t('candidate.offers.fenced') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{ $filters.formatAmount(stats.jobStatus[2].number, false) }}
								</div>

								<hr class="mt-3" />

								<div class="col-6 pb-1 text-start">
									{{ $t('candidate.offers.accepted_position') }}:
								</div>
								<div class="col-6 pb-1 text-alt- text-end">
									{{
										$filters.formatAmount(
											stats.applicationStatus[1].number,
											false
										)
									}}
								</div>

								<hr class="mt-2" />

								<div class="col-6 pb-1 text-start">
									{{ $t('candidate.offers.intern') }}:
								</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{
										$filters.formatAmount(stats.jobContract[2].number, false)
									}}
								</div>
								<div class="col-6 py-1 text-start">CDD:</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{
										$filters.formatAmount(stats.jobContract[0].number, false)
									}}
								</div>
								<div class="col-6 py-1 text-start">CDI:</div>
								<div class="col-6 py-1 text-alt- text-end">
									{{
										$filters.formatAmount(stats.jobContract[1].number, false)
									}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import { useDispositionStore } from '@/store/disposition';

export default {
	setup() {
		const store = useDispositionStore();
		return { store };
	},
	name: 'OfferPage',
	components: { Template, LoadingMain },
	data() {
		return {
			deleted: false,
			archived: false,
			loading: true,
			selectedList: [],
			allIsCheck: false,
			page: 1,
			perPage: 25,
			disposition: 'list',
			orderBy: 'id',
			order: 'DESC',
			bests: [],
			offers: [],
			offer_selected: {},
			stats: {},
			allList: [],
			searchText: ""
		};
	},
	methods: {
		check(id, all = false) {
			if (all) {
				if (this.selectedList.length !== this.offers.length) {
					this.selectedList.length = 0;
					this.offers.forEach((offer) => {
						this.selectedList.push({ id: offer.id });
					});
				} else {
					this.selectedList.length = 0;
				}
			} else {
				let found = false;
				this.selectedList.forEach((element) => {
					if (element.id == id) {
						found = true;
					}
				});
				if (found) {
					this.selectedList.filter((value, index) => {
						if (value.id == id) {
							this.selectedList.splice(index, 1);
						}
					});
				} else {
					this.selectedList.push({ id: id });
				}
			}
			if (this.selectedList.length === this.offers.length) {
				this.allIsCheck = true;
			} else {
				this.allIsCheck = false;
			}
		},
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.offers.length) {
				this.page++;
			}
		},
		search() {
			if (this.searchText != "") {
				this.searchText = this.searchText.toLowerCase()
				const founds = this.allList.filter(element =>
					element.post?.toLowerCase().includes(this.searchText) 
				);

				this.offers = founds
			} else {
				this.offers = this.allList
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		isSelected(id) {
			let found = false;
			this.selectedList.forEach((element) => {
				if (element.id == id) {
					found = true;
				}
			});
			return found;
		},
		loadList() {
			this.loading = true;
			fetch(
				Helper.route(
					'admin/joboffer/all?sortBy=' + this.orderBy + '&order=' + this.order
				),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					console.log(data);
					if (data.data) {
						this.offers = data.data;
						this.bests = data.recents;
						this.allList = this.offers
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		manage(status) {
			if (
				!['opened', 'suspended', 'closed', 'canceled', 'deleted'].includes(
					status
				)
			) {
				return;
			}

			this.$isLoading(true);
			let body = {
				ids: this.selectedList.map((element) => element.id),
				status: status,
			};

			fetch(
				Helper.route('admin/joboffer/manage'),
				Helper.requestOptionsJSON('PUT', body)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.$isLoading(false);
					console.log(data);

					this.deleted = true;
					for (let i = 0; i < this.selectedList.length; i++) {
						const element = this.selectedList[i];
						if (status == 'deleted') {
							this.offers = this.offers.filter((i) => i.id !== element.id);
							this.bests = this.bests.filter((i) => i.id !== element.id);
						} else {
							this.offers.find((o) => o.id === element.id).status = status;
						}
					}

					this.selectedList = [];
					// Helper.notification("success", data.message)
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		loadStats() {
			this.loading = true;
			fetch(Helper.route('admin/application/stats'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					console.log(data);
					this.stats = data.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	created() {
		this.loadList();
		this.loadStats();
	},
	watch: {
		orderBy: function () {
			this.loadList();
		},
		order: function () {
			this.loadList();
		},
		searchText: function () {
			this.search();
		},
	},
};
</script>
<style>
.candidate_image_list {
	height: 80px;
}
.offer_status {
	width: fit-content;
	border-radius: 25px;
}
.offer_icon {
	color: #ffff00;
}
</style>
