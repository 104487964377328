<template>
	<div>
		<Template
			:title="`${$t('candidate.offers.offers_candidate')}: ${offer.post}`"
			:sidebarMenu="'offers'"
		>
			<!-- <div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<router-link to="/offers" class="bg-light-hight- ms-2 text-6 top-item"
					>Offres d'emploi</router-link
				>
				<router-link
					to="/candidates-profiles"
					class="bg-light-hight- ms-4 text-6 top-item"
					>Profils candidats</router-link
				>
				<router-link
					to="/test-brainstorming"
					class="bg-light-hight- ms-2 text-6 top-item"
					>{{$t('suscribe.brainstorming_test')}}</router-link
				>
			</div> -->

			<section class="row px-4 m-0 g-4 mt-1">
				<!-- Modal -->
				<div
					class="modal fade"
					id="deleteModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-red-">
									{{ $t('global.deletion_warning') }}
								</h6>
								<hr />
								<p class="text-5" v-show="!deleted">
									{{ $t('candidate.offers.candidacy_deletion_warning') }}
								</p>
								<p class="text-5 text-center" v-show="deleted">
									{{ $t('global.deleted_successfully') }}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{ $t('global.close') }}
										</button>
									</div>
									<div class="col text-center" v-show="!deleted">
										<button
											type="button"
											class="bg-red- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
											@click="deletes()"
										>
											{{ $t('global.delete') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Back />

				<div class="col-xl-9">
					<div v-if="!loading && greens.length != 0">
						<div class="row">
							<div
								class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
							>
								{{ $t('candidate.offers.green_talents') }}
							</div>
						</div>
					</div>

					<div class="row mt-1 g-2 mb-4">
						<div
							v-for="(application, index) in greens"
							:key="index"
							class="col-xl-3"
						>
							<div class="bg-light-hight-1 text-center rounded">
								<div class="text-center p-2">
									<div class="">
										<i
											class="uil uil-check-square text-success box_icon fs-1"
										></i>
									</div>
									<div class="text-5 text-black-">
										<router-link
											:to="{
												name: 'application',
												params: {
													id: application.id,
													slug: application.slug,
												},
											}"
										>
											{{
												$filters.truncate(application.applicant.lastname, 30)
											}}
											{{
												$filters.truncate(application.applicant.firstname, 30)
											}}</router-link
										>
									</div>
								</div>
								<div class="bg-second- text-white rounded p-2 text-6">
									<span class="bg-success text-white box_status p-1">
										{{ $t('global.accepted') }}</span
									>
								</div>
							</div>
						</div>
						<!-- <div
							v-if="greens.length == 0"
							class="text-center text-danger text-6"
						>
							Aucune canditature acceptée pour le moment
						</div> -->
					</div>

					<div class="row">
						<div class="hstack">
							<router-link
								to="/offer/create"
								class="bg-second- rounded ms-auto px-3 p-1"
							>
								<i class="uil uil-plus text-white"></i>
								<span class="ms-2 text-6 text-white">{{
									$t('candidate.offers.post_offer')
								}}</span>
							</router-link>
						</div>
						<div class="hstack mt-4 p-2 px-4 rounded shadow-1 bg-white">
							<input type="checkbox" @click="check(0, true)" />
							<i
								class="uil uil-trash fs-6 ms-3"
								type="button"
								data-mdb-toggle="modal"
								data-mdb-target="#deleteModal"
								@click="deleted = false"
							></i>

							<div class="hstack ms-auto">
								<label for="" class="text-5 me-2">{{
									$t('global.filter')
								}}</label>
								<select class="form-control text-6" v-model="orderBy">
									<option value="id">{{ $t('global.signup_order') }}</option>
									<option value="name">{{ $t('candidate.candidate') }}</option>
									<option value="gender">{{ $t('global.sex') }}</option>
									<option value="note">{{ $t('global.note') }}</option>
									<option value="status">{{ $t('global.status') }}</option>
								</select>

								<label for="" class="text-5 ms-2 me-2">{{
									$t('global.order')
								}}</label>
								<select class="form-control text-6" v-model="order">
									<option value="ASC">ASC</option>
									<option value="DESC">DESC</option>
								</select>
							</div>

							<div class="ms-auto hstack">
								<i
									class="uil uil-arrow-circle-left fs-5"
									type="button"
									@click="previous"
								></i>
								<small class="ms-3"
									>{{
										Math.ceil(applications.length / perPage) > 0 ? page : 0
									}}
									/ {{ Math.ceil(applications.length / perPage) }}</small
								>
								<i
									class="uil uil-arrow-circle-right fs-5 ms-3"
									type="button"
									@click="next"
								></i>
								<div class="hstack ms-5">
									<i
										class="uil uil-apps fs-6 p-1 px-3 rounded"
										:class="
											store.disposition == 'grid'
												? 'bg-black- text-white-'
												: 'bg-white- text-black-'
										"
										@click="store.setDisposition('grid')"
										type="button"
									></i>
									<i
										class="uil uil-list-ul fs-6 ms-1 p-1 px-3 rounded"
										:class="
											store.disposition == 'list'
												? 'bg-black- text-white-'
												: 'bg-white- text-black-'
										"
										@click="store.setDisposition('list')"
										type="button"
									></i>
								</div>
							</div>
						</div>

						<div class="p-0 mb-4" v-if="!loading && applications.length !== 0">
							<div class="table-responsive" v-if="store.disposition == 'list'">
								<table
									class="table profiles table-hover table-striped table-condensed mb-0"
								>
									<thead class="bg-primary-">
										<tr class="text-6 text-white">
											<th></th>
											<th>{{ $t('candidate.candidate') }}</th>
											<!-- <th>Date de naissance</th>
											<th>Lieu de naissance</th> -->
											<th>{{ $t('global.contact') }}</th>
											<th>{{ $t('global.sex') }}</th>
											<th>{{ $t('global.note') }}</th>
											<th class="text-center td-w">
												{{ $t('global.status') }}
											</th>
											<th>{{ $t('global.signup') }}</th>
										</tr>
									</thead>
									<tbody class="bg-alt-">
										<tr
											v-for="(application, index) in applications"
											:key="application.id"
											v-show="show(index)"
											class="text-6 text-black"
										>
											<td>
												<input
													type="checkbox"
													:checked="isSelected(application.id)"
													@click="check(application.id)"
												/>
											</td>
											<td>
												<router-link
													:to="{
														name: 'application',
														params: {
															id: application.id,
															slug: application.slug,
														},
													}"
												>
													{{ application.applicant.lastname }}
													{{ application.applicant.firstname }}
												</router-link>
											</td>
											<!-- <td>
												{{
													$filters.convertDate(
														new Date(application.applicant.birthdate).toJSON()
													)
												}}
											</td>
											<td>{{ application.applicant.birthplace }}</td> -->
											<td>
												{{ application.applicant.emailAddress }} |
												{{ application.applicant.phoneNumber1 }}
											</td>
											<td>
												{{
													application.applicant.gender == 'M'
														? $t('global.male')
														: $t('global.feminine')
												}}
											</td>
											<td>
												{{ application.result?.points ?? 'N' }}/{{
													application.result?.total ?? 'A'
												}}
											</td>
											<td
												class="text-center status"
												v-if="application.status == 'refused'"
											>
												<kbd class="bg-red- text-white rounded-pill px-3 p-1">
													{{ $t('global.denied') }}
												</kbd>
											</td>
											<td
												class="text-center status"
												v-else-if="application.status == 'pending'"
											>
												<kbd class="bg-light text-dark rounded-pill px-3 p-1">
													{{ $t('global.pending') }}
												</kbd>
											</td>
											<td class="text-center status" v-else>
												<kbd class="bg-green- text-white rounded-pill px-3 p-1">
													{{ $t('global.accepted') }}
												</kbd>
											</td>
											<td>
												{{
													$filters.convertDate(
														new Date(application.createdAt).toJSON()
													)
												}}
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div v-else class="row mt-1 g-2">
								<div
									v-for="(application, index) in applications"
									:key="index"
									v-show="show(index)"
									class="col-md-3"
								>
									<div class="bg-primary- text-center rounded pt-2">
										<div
											v-if="application.status == 'refused'"
											class="mx-2 bg-red- rounded-circle"
											style="width: 10px; height: 10px"
										></div>
										<div
											v-else-if="application.status == 'pending'"
											class="mx-2 bg-light- rounded-circle"
											style="width: 10px; height: 10px"
										></div>
										<div
											v-else
											class="mx-2 bg-green- rounded-circle"
											style="width: 10px; height: 10px"
										></div>
										<div class="text-center p-2">
											<div class="">
												<img
													class="w-50 h-50"
													src="@/assets/images/cv-lg.png"
													alt=""
													srcset=""
												/>
											</div>
										</div>
										<div class="bg-white- text-dark rounded p-2 text-6">
											<input
												type="checkbox"
												class="me-1"
												:checked="isSelected(application.id)"
												@click="check(application.id)"
											/>
											<router-link
												:to="{
													name: 'application',
													params: {
														id: application.id,
														slug: application.slug,
													},
												}"
											>
												{{ application.applicant.lastname }}
												{{ application.applicant.firstname }}
											</router-link>
											<!-- <span
												v-if="application.status === 'refused'"
												class="bg-red- text-white offer_status p-1"
											>
												{{$t('global.denied')}}</span
											>
											<span
												v-else-if="application.status === 'pending'"
												class="bg-light text-dark offer_status p-1"
											>
												{{$t('global.pending')}}</span
											>
											<span
												v-else
												class="bg-green- text-white offer_status p-1"
											>
												accepté</span
											> -->
										</div>
									</div>
								</div>
							</div>
						</div>

						<div
							class="py-4 text-center text-black-"
							v-if="!loading && applications.length === 0"
						>
							{{ $t('global.no_data') }}
						</div>

						<LoadingMain :state="loading" />
					</div>
				</div>
				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
						v-html="$t('candidate.offers.offer_applicant_advice')"
					></div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import Back from '@/components/Back.vue';
import { useDispositionStore } from '@/store/disposition';

export default {
	setup() {
		const store = useDispositionStore();
		return { store };
	},
	name: 'CandidatesPage',
	components: { Template, LoadingMain, Back },
	data() {
		return {
			deleted: false,
			archived: false,
			loading: true,
			selectedList: [],
			allIsCheck: false,
			page: 1,
			perPage: 12,
			disposition: 'list',
			orderBy: 'id',
			order: 'DESC',
			greens: [],
			applications: [],
			offer: { post: '' },
		};
	},
	methods: {
		check(id, all = false) {
			if (all) {
				if (this.selectedList.length !== this.applications.length) {
					this.selectedList.length = 0;
					this.applications.forEach((offer) => {
						this.selectedList.push({ id: offer.id });
					});
				} else {
					this.selectedList.length = 0;
				}
			} else {
				let found = false;
				this.selectedList.forEach((element) => {
					if (element.id == id) {
						found = true;
					}
				});
				if (found) {
					this.selectedList.filter((value, index) => {
						if (value.id == id) {
							this.selectedList.splice(index, 1);
						}
					});
				} else {
					this.selectedList.push({ id: id });
				}
			}
			if (this.selectedList.length === this.applications.length) {
				this.allIsCheck = true;
			} else {
				this.allIsCheck = false;
			}
		},
		show(index) {
			return (
				index < this.page * this.perPage &&
				index + 1 > (this.page - 1) * this.perPage
			);
		},
		next() {
			if (this.page * this.perPage < this.applications.length) {
				this.page++;
			}
		},
		previous() {
			if (this.page >= 2) {
				this.page--;
			}
		},
		isSelected(id) {
			let found = false;
			this.selectedList.forEach((element) => {
				if (element.id == id) {
					found = true;
				}
			});
			return found;
		},
		testResult(applicationId) {
			return this.offer.testResults.find(
				(t) => t.application === applicationId
			);
		},
		loadList() {
			this.loading = true;
			fetch(Helper.route('admin/joboffer/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					return response.json();
				})
				.then((data) => {
					console.log(data);
					if (data.data) {
						this.offer = data.data;
						this.applications = data.data.applications.map((a) => {
							return { ...a, result: this.testResult(a.id) };
						});
						this.greens = this.applications
							.filter((a) => a.status === 'accepted')
							.slice(0, 1);
					}
					this.loading = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		deletes() {
			if (this.selectedList.length > 0) {
				this.$isLoading(true);
				let body = {
					ids: this.selectedList.map((element) => element.id),
					status: 'deleted',
				};
				fetch(
					Helper.route('admin/application/manage'),
					Helper.requestOptionsJSON('PUT', body)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						return response.json();
					})
					.then((data) => {
						this.$isLoading(false);
						console.log(data);
						this.deleted = true;
						for (let i = 0; i < this.selectedList.length; i++) {
							const element = this.selectedList[i];
							this.applications = this.applications.filter(
								(i) => i.id !== element.id
							);
						}
						this.selectedList = [];
						Helper.notification('success', data.message);
					})
					.catch((error) => {
						this.$isLoading(false);
						console.log(error);
					});
			} else {
				Helper.notification(
					'error',
					'Veuillez sélectionner au moins une candidature à supprimer.'
				);
			}
		},
	},
	watch: {
		orderBy: function (ordB) {
			console.log('HEHE', this.applications);

			switch (ordB) {
				case 'id':
					if (this.order === 'ASC') {
						this.applications.sort((a, b) => a.id - b.id);
					} else {
						this.applications.sort((a, b) => b.id - a.id);
					}
					break;
				case 'name':
					if (this.order === 'ASC') {
						this.applications.sort((a, b) =>
							a.applicant.lastname.localeCompare(b.applicant.lastname)
						);
					} else {
						this.applications.sort((a, b) =>
							b.applicant.lastname.localeCompare(a.applicant.lastname)
						);
					}
					break;
				case 'note':
					if (this.order === 'ASC') {
						this.applications.sort(
							(a, b) => (a.result?.points ?? 0) - (b.result?.points ?? 0)
						);
					} else {
						this.applications.sort(
							(a, b) => (b.result?.points ?? 0) - (a.result?.points ?? 0)
						);
					}
					break;
				case 'gender':
					if (this.order === 'ASC') {
						this.applications.sort((a, b) =>
							a.applicant.gender.localeCompare(b.applicant.gender)
						);
					} else {
						this.applications.sort((a, b) =>
							b.applicant.gender.localeCompare(a.applicant.gender)
						);
					}
					break;
				case 'status':
					if (this.order === 'ASC') {
						this.applications.sort((a, b) => a.status.localeCompare(b.status));
					} else {
						this.applications.sort((a, b) => b.status.localeCompare(a.status));
					}
					break;
				default:
					this.loadList();
					break;
			}
		},
		order: function (ord) {
			switch (this.orderBy) {
				case 'id':
					if (ord === 'ASC') {
						this.applications.sort((a, b) => a.id - b.id);
					} else {
						this.applications.sort((a, b) => b.id - a.id);
					}
					break;
				case 'name':
					if (ord === 'ASC') {
						this.applications.sort((a, b) =>
							a.applicant.lastname.localeCompare(b.applicant.lastname)
						);
					} else {
						this.applications.sort((a, b) =>
							b.applicant.lastname.localeCompare(a.applicant.lastname)
						);
					}
					break;
				case 'note':
					if (ord === 'ASC') {
						this.applications.sort(
							(a, b) => (a.result?.points ?? 0) - (b.result?.points ?? 0)
						);
					} else {
						this.applications.sort(
							(a, b) => (b.result?.points ?? 0) - (a.result?.points ?? 0)
						);
					}
					break;
				case 'gender':
					if (ord === 'ASC') {
						this.applications.sort((a, b) =>
							a.applicant.gender.localeCompare(b.applicant.gender)
						);
					} else {
						this.applications.sort((a, b) =>
							b.applicant.gender.localeCompare(a.applicant.gender)
						);
					}
					break;
				case 'status':
					if (ord === 'ASC') {
						this.applications.sort((a, b) => a.status.localeCompare(b.status));
					} else {
						this.applications.sort((a, b) => b.status.localeCompare(a.status));
					}
					break;
				default:
					this.loadList();
					break;
			}
		},
	},
	created() {
		this.id = this.$route.params.id;
		this.loadList();
	},
};
</script>
<style>
table.table {
	width: auto !important;
}

.box_status {
	width: fit-content;
	border-radius: 25px;
}

.bg-gray {
	background-color: #7c7a7a;
}

.td-w {
	width: 150px !important;
}

.profiles th,
.profiles td {
	color: white !important;
}

.profiles td.status {
	min-width: 150px !important;
}
</style>
