<template>
	<div class="fixed-top bg-dark hstack topbar">
		<div class="hstack py-3 me-5">
			<!-- top-left -->
			<img src="@/assets/images/SANS_FOND_LOGO_BLANC.png" alt="" />
			<h6 class="border-right ms-3 mt-1 text-white">PRO GESTION SOFT</h6>
		</div>
		<div class="hstack mx-3">
			<div class="ms-3 dropdown">
				<i
					class="uil uil-bars text-white fs-5"
					type="button"
					id="dropdownMenuButton"
					data-mdb-toggle="dropdown"
					aria-expanded="false"
				></i>
				<ul
					class="dropdown-menu bg-white-"
					aria-labelledby="dropdownMenuButton"
				>
					<li>
						<a
							class="dropdown-item text-6 py-1 text-black-"
							:href="SITE_URL + 'about'"
							target="_blank"
							>{{ $t('topbar.about') }}</a
						>
					</li>
					<li>
						<a
							class="dropdown-item text-6 py-1 text-black-"
							:href="SITE_URL + 'legal-notice'"
							target="_blank"
							>{{ $t('topbar.legal_mention') }}</a
						>
					</li>
					<li>
						<a
							class="dropdown-item text-6 py-1 text-black-"
							:href="SITE_URL + 'terms'"
							target="_blank"
							>{{ $t('topbar.terms') }}</a
						>
					</li>
					<li>
						<a
							class="dropdown-item text-6 py-1 text-black-"
							:href="SITE_URL + 'protection-policy'"
							target="_blank"
							>{{ $t('topbar.protection_policy') }}</a
						>
					</li>
					<router-link to="assistance" class="dropdown-item text-6 py-1"
						><span class="">{{ $t('topbar.assistance') }}</span></router-link
					>
					<li>
						<a
							class="dropdown-item text-6 py-1 text-black-"
							:href="SITE_URL + 'faq'"
							target="_blank"
							>{{ $t('topbar.faq') }}</a
						>
					</li>
					<li>
						<a
							class="dropdown-item text-6 py-1 text-black-"
							:href="SITE_URL + 'support-us'"
							target="_blank"
							>{{ $t('topbar.support_us') }}</a
						>
					</li>
					<router-link
						to="comments"
						class="dropdown-item text-6 py-1 text-black-"
						><span class="">{{ $t('topbar.comments') }}</span></router-link
					>
				</ul>
			</div>

			<div class="ms-4">
				<router-link
					:to="{ name: 'notification' }"
					class="text-6 py-1 text-black"
				>
					<div v-if="unreadNotification > 0">
						<i class="uil uil-bell text-white fs-5" type="button"></i>
						<span class="badge rounded-pill badge-notification bg-danger">{{
							unreadNotification >= 100 ? '99+' : unreadNotification
						}}</span>
					</div>
					<i v-else class="uil uil-bell text-white fs-5" type="button"></i>
				</router-link>
			</div>

			<div class="ms-4 d-none dropdown">
				<i
					class="uil uil-users-alt fs-5 text-white"
					type="button"
					id="dropdownMenuButton"
					data-mdb-toggle="dropdown"
					aria-expanded="false"
				></i>
				<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
					<li>
						<a class="dropdown-item text-6 py-1 text-primary-" href="#"
							>Au total</a
						>
					</li>
					<li>
						<a class="dropdown-item text-6 py-1 text-black" href="#"
							>Gutenberg</a
						>
					</li>
					<li>
						<a class="dropdown-item text-6 py-1 text-black" href="#"
							>DuBuzz 66</a
						>
					</li>
				</ul>
			</div>

			<div class="ms-4 dropdown">
				<i
					class="uil uil-lock-access fs-5 text-white"
					type="button"
					id="dropdownMenuButton"
					data-mdb-toggle="dropdown"
					aria-expanded="false"
				></i>
				<ul
					class="dropdown-menu bg-white-"
					aria-labelledby="dropdownMenuButton"
				>
					<li v-if="store.user.isMainAdmin">
						<router-link
							:to="{ name: 'admin' }"
							class="dropdown-item text-6 py-1 text-black"
						>
							<span>{{ $t('topbar.co_admin') }}</span>
						</router-link>
					</li>
					<li v-if="!store.user.isMainAdmin">
						<router-link
							:to="{ name: 'view-rights' }"
							class="dropdown-item text-6 py-1 text-black"
						>
							<span>{{ $t('Mes droits') }}</span>
						</router-link>
					</li>
					<li>
						<router-link
							:to="{ name: 'subscription' }"
							class="dropdown-item text-6 py-1 text-black"
						>
							<span>{{ $t('topbar.my_subscriptions') }}</span>
						</router-link>
					</li>
					<li>
						<router-link
							:to="{ name: 'pricing' }"
							class="dropdown-item text-6 py-1 text-black"
						>
							<span>{{ $t('topbar.subscription') }}</span>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
		<div class="ms-auto hstack">
			<form action="">
				<Search></Search>
			</form>
			<div class="ms-4 dropdown">
				<i
					class="uil uil-setting fs-5 text-white"
					type="button"
					id="dropdownMenuButton"
					data-mdb-toggle="dropdown"
					aria-expanded="false"
				></i>
				<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
					<li>
						<a class="dropdown-item dropstart text-6 py-1 text-black" href="#"
							>&laquo; {{ $t('topbar.set_theme') }}</a
						>
						<ul
							class="dropdown-menu dropdown-submenu dropdown-start"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<li>
								<a
									class="dropdown-item text-6 py-1 text-black"
									href="#"
									@click="setTheme('light')"
									>{{ $t('topbar.light') }}</a
								>
							</li>
							<li>
								<a
									class="dropdown-item text-6 py-1 text-black"
									href="#"
									@click="setTheme('dark')"
									>{{ $t('topbar.dark') }}</a
								>
							</li>
						</ul>
					</li>
					<li>
						<a class="dropdown-item dropstart text-6 py-1 text-black" href="#"
							>&laquo; {{ $t('topbar.set_language') }}</a
						>
						<ul class="dropdown-menu dropdown-submenu dropdown-start">
							<li>
								<a
									class="dropdown-item text-6 py-1 text-black"
									type="button"
									@click="setLanguage('fr')"
									>{{ $t('topbar.french') }}</a
								>
							</li>
							<li>
								<a
									class="dropdown-item text-6 py-1 text-black"
									type="button"
									@click="setLanguage('en')"
									>{{ $t('topbar.english') }}</a
								>
							</li>
						</ul>
					</li>
				</ul>
			</div>

			<div class="ms-4 d-none dropdown">
				<i
					class="uil uil-lock fs-5 text-white"
					type="button"
					id="dropdownMenuButton"
					data-mdb-toggle="dropdown"
					aria-expanded="false"
				></i>
				<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
					<li>
						<a class="dropdown-item dropstart text-6 py-1 text-black" href="#"
							>Changer la langue &raquo;</a
						>
						<ul class="dropdown-menu dropdown-submenu">
							<li>
								<span
									class="dropdown-item text-6 py-1 text-black"
									type="button"
									@click="setLanguage('fr')"
									>Français</span
								>
							</li>
							<li>
								<span
									class="dropdown-item text-6 py-1 text-black"
									type="button"
									@click="setLanguage('en')"
									>Anglais</span
								>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="ms-4 d-none dropdown">
				<i
					class="uil uil-lock fs-5 text-white"
					type="button"
					id="dropdownMenuButton"
					data-mdb-toggle="dropdown"
					aria-expanded="false"
				></i>
				<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
					<li>
						<a class="dropdown-item dropstart text-6 py-1 text-black" href="#"
							>Changer la langue &raquo;</a
						>
						<ul class="dropdown-menu dropdown-submenu">
							<li>
								<span class="dropdown-item text-6 py-1 text-black" href="#"
									>Français</span
								>
							</li>
							<li>
								<span class="dropdown-item text-6 py-1 text-black" href="#"
									>Anglais</span
								>
							</li>
						</ul>
					</li>
				</ul>
			</div>

			<div class="mx-4 py-1 dropdown">
				<div
					class="hstack"
					type="button"
					id="dropdownMenuButton"
					data-mdb-toggle="dropdown"
					aria-expanded="false"
				>
					<img
						:src="user?.photo?.url"
						class="rounded-circle user_image me-2"
						alt=""
					/>
					<span class="text-white text-5">{{ user?.firstname }}</span>
				</div>
				<ul
					class="dropdown-menu rounded-0"
					aria-labelledby="dropdownMenuButton"
				>
					<div class="dropdown-account bg-white- text-center">
						<img
							:src="user?.company?.logo.url"
							class="rounded-circle user_image_big mt-3"
							alt=""
						/>
						<hr />
						<p
							class="text-5 text-center text-black- mt-2 mb-0 px-2"
							v-show="user?.firstname != null"
						>
							{{ user?.firstname + ' ' + user?.lastname }}
						</p>
						<p class="text-5 text-center text-black- mt-2 mb-0 px-2">
							{{ user?.company?.name }}
						</p>
						<p class="text-5 text-center text-black- mt-2 mb-0 px-2">
							{{ user?.company?.ifu }}
						</p>
						<p class="text-5 text-center text-black- mt-2 mb-0 px-2">
							{{ user?.company?.rccm }}
						</p>
						<p class="text-5 text-center text-black- mt-2 mb-0 px-2">
							{{ user?.emailAddress }}
						</p>
						<p class="text-5 text-center text-black- mt-2 mb-0 px-2">
							{{ user?.phoneNumber }}
						</p>
						<p class="text-5 text-center text-black- mt-2 mb-2 px-2">
							<routerLink
								to="/setting"
								class="bg-alt- text-white px-3 py-1 rounded shadow-sm"
							>
								{{ $t('topbar.develop') }}
							</routerLink>
						</p>
						<p
							type="button"
							@click="logout"
							class="bg-black- text-white- mb-0 w-100 text-4 py-1 logout-btn"
						>
							Déconnexion
						</p>
					</div>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import Helper from '@/helpers/helper';
import { useUserStore } from '@/store/user';
import Search from '@/components/Search.vue';
export default {
	setup() {
		const store = useUserStore();
		return {
			store,
		};
	},
	components: { Search },
	name: 'TopbarComponent',
	data() {
		return {
			SITE_URL: '',
			user: this.store.user,
			unreadNotification: 0,
		};
	},
	methods: {
		goto_view_rights() {
			this.$router.push({ name: 'view-rights' });
		},
		logout() {
			this.$swal({
				title: `${this.$t('global.attention')}!`,
				text: `${this.$t('global.logout_warning')}`,
				icon: 'warning',
				showCancelButton: true,
				cancelButtonText: `${this.$t('global.logout_cancel')}`,
				confirmButtonText: `${this.$t('global.logout_ok')}`,
			}).then((result) => {
				if (result.isConfirmed) {
					localStorage.removeItem(Helper.CLIENT_TOKEN);
					Helper.redirect(Helper.route('login', null, false));
				}
			});
		},
		checkNull(input) {
			return input != 'null' && input != null ? input : '';
		},
		getUser() {
			fetch(Helper.route('admin/profile/get-data'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.user = data.data;
					document.body.classList.add(this.user?.preferences?.theme);
					this.store.setUser(this.user);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		setTheme(theme = null) {
			if (theme) {
				document.body.classList = '';
				document.body.classList.add(theme);

				let themeFormData = new FormData();
				themeFormData.append('theme', theme);
				themeFormData.append('language', this.user?.preferences?.language);

				fetch(
					Helper.route('admin/profile/update-preferences'),
					Helper.requestOptions('PUT', themeFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						console.log(data);
						if (data.status) {
							this.store.user.preferences.theme = theme;
						}
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				if (this.user?.preferences?.theme) {
					document.body.classList = '';
					document.body.classList.add(this.user?.preferences?.theme);
				}
			}
		},
		setLanguage(language = null) {
			if (language) {
				let languageFormData = new FormData();
				languageFormData.append('theme', this.user?.preferences?.theme);
				languageFormData.append('language', language);

				fetch(
					Helper.route('admin/profile/update-preferences'),
					Helper.requestOptions('PUT', languageFormData)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						if (response.status != 200) {
							this.ok = false;
						}
						return response.json();
					})
					.then((data) => {
						if (data.status) {
							localStorage.setItem('language', language);
							this.store.user.preferences.language = language;
							this.$router.go(0);
						}
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				if (this.user?.preferences?.language) {
					localStorage.setItem('language', this.user?.preferences?.language);
				}
			}
		},

		loadNotifications() {
			this.loading = true;
			fetch(Helper.route('admin/notification/all'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					const notifications = data.data;

					this.unreadNotification = notifications.filter(
						(n) => n.status === 'unread'
					).length;

					console.log(this.unreadNotification);
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
	mounted() {
		this.setTheme();
		this.setLanguage();
	},
	created() {
		this.SITE_URL = Helper.SITE_URL;
		this.getUser();
		this.loadNotifications();
	},
};
</script>

<style>
.topbar {
	height: 48px;
}
.topbar img {
	height: 45px;
}
.user_image {
	height: 20px !important;
	width: 20px;
	object-fit: cover;
}
.user_image_big {
	height: 80px !important;
	width: 80px;
	object-fit: cover;
}
.dropdown-account {
	width: 270px !important;
	background-color: #f6f6f6;
}
.dropdown-menu.dropdown-submenu {
	left: -150px !important;
	right: 0 !important;
	width: 100% !important;
}

.dropdown-submenu .dropdown-menu {
	left: auto !important;
	right: 100% !important;
}

.logout-btn:hover {
	background: red !important;
}
</style>
