<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<div>
		<Template title="null" sidebarMenu="employees">
			<section class="row px-4 m-0 g-4 mt-1">
				<Back />

				<!-- Modal -->
				<div
					class="modal fade"
					id="deleteModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<form @submit.prevent="addPayslip" class="modal-body">
								<div class="text-primary- text-5 py-3" v-if="message.length > 0">
									{{ message }}
								</div>
								<div class="hstack">
									<h3 class="text-4">Ajouter un élément</h3>
									<i class="uil uil-multiply ms-auto fs-6" data-mdb-dismiss="modal" type="button"></i>
								</div>
								<hr />
								<div v-if="!showList">
									<InputFC
										:type="'text'"
										class="mb-4"
										v-model="code"
										:required="true"
										:placeholder="$t('resources.code')"
									/>
									<InputFC
										:type="'text'"
										class="mb-4"
										v-model="element"
										:required="true"
										:placeholder="$t('resources.element')"
									/>
									<InputFC
										:type="'select'"
										class="mb-4"
										:options="natures"
										v-model="nature"
										:required="true"
										:placeholder="$t('resources.nature')"
									/>
									<InputFC
										:type="'number'"
										class="mb-4"
										v-if="nature == 'gain'"
										v-model="gain"
										:required="true"
										:placeholder="$t('resources.gain')"
									/>
									<InputFC
										:type="'number'"
										class="mb-4"
										:required="true"
										v-if="nature == 'deduction'"
										v-model="deduction"
										:placeholder="$t('resources.deduction')"
									/>
									<InputFC
										:type="'number'"
										class="mb-4"
										:required="true"
										v-if="nature == 'reminder'"
										v-model="reminder"
										:placeholder="$t('resources.reminder')"
									/>
								</div>
								<div v-else class="table-responsive">
									<table class="table text-6" style="width: 100% !important">
										<thead class="bg-primary-">
											<tr class="text-6 text-white-">
												<th class="text-white-">{{$t('resources.code')}}</th>
												<th class="text-white-">{{$t('resources.element')}}</th>
												<th class="text-white-">{{$t('resources.gain')}}</th>
												<th class="text-white-">{{$t('resources.detention')}}</th>
												<th class="text-white-">{{$t('resources.reminder')}}</th>
											</tr>
										</thead>
										<tbody>
											<tr
												class="text-6"
												v-for="(payslip, index) in rows"
												:key="index"
											>
												<td>{{ payslip.code }}</td>
												<td>{{ payslip.element }}</td>
												<td>{{ payslip.gain }}</td>
												<td>{{ payslip.deduction }}</td>
												<td>{{ payslip.reminder }}</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div class="hstack">
									<span type="button" class="button-outline-primary mt-4" @click="showList = !showList" v-if="rows.length >= 0">{{ !showList ? $t('global.view_list') : $t('global.hide_list') }}</span>
									<div class="ms-2" v-if="showList == false">
										<SpinnerMe :elementId="'spinner'" />
										<span type="button" @click="addPayslip()" class="button ms-4 px-4 py-1 mt-4">
											{{ $t('global.add') }}
										</span>
									</div>
									<div class="ms-2" v-if="rows.length > 0">
										<SpinnerMe :elementId="'spinner'" />
										<button id="submit" @click="savePayslip()" class="button bg-red- ms-4 px-4 py-1 mt-4">
											{{ $t('global.save') }}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>


				
				<div class="col-lg-12">
					<div
						class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
					>
						{{$t('global.preview')}}
					</div>
					<LoadingMain :state="loading" />
					<div
						v-if="employee == null && !loading"
						class="p-5 text-red- text-5 text-center"
					>
						{{$t('resources.cant_find_employee')}}
					</div>
					<div class="row mt-1 g-4" v-if="employee !== null">
						<div class="col-xl-6">
							<div class="border rounded mb-4">
								<div
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('resources.employee_infos')}}
								</div>
								<div class="hstack p-3 py-1">
									<i
										class="uil uil-info-circle text-second- fs-4 ms-auto"
										type="button"
										v-show="!showInfo"
										@click="showInfo = !showInfo"
									></i>
									<i
										class="uil uil-times-circle text-second- fs-4 ms-auto"
										type="button"
										v-show="showInfo"
										@click="showInfo = !showInfo"
									></i>
								</div>
								<div class="text-center">
									<img
										:src="employee.photo.url"
										class="rounded-circle w-25 h-25"
										alt=""
									/>
								</div>
								<div class="text-center m-2">
									<h3 class="text-primary- fs-2">
										{{ employee.lastname + ' ' + employee.firstname }}
									</h3>
									<p class="text-5 text-second- mt-2 mb-1">
										{{ employee.job }}
									</p>
									<div class="fit-content- hstack mx-auto">
										<div class="text-center me-3">
											<i class="uil uil-phone text-primary- fs-5"></i> <br />
											{{ employee.phoneNumber }}
										</div>
										<div class="text-center ms-auto me-3">
											<i class="uil uil-envelope text-primary- fs-5"></i> <br />
											{{ employee.emailAddress }}
										</div>
										<div class="text-center ms-auto">
											<i
												class="uil uil-location-pin-alt text-primary- fs-5"
											></i>
											<br />
											{{ employee.address }}
										</div>
									</div>
								</div>

								<div v-show="!showInfo" class="text-center m-2">
									<p class="text-5 text-second- mt-2 mb-1">
										{{
											employee.gender == 'M'
												? $t('global.male')
												: employee.gender == 'F'
												? $t('global.feminine')
												: ''
										}}
										{{ $filters.convertDate(employee.birthDate, false) }}
									</p>
									<p class="text-5 text-second- mt-2 mb-1">
										{{ employee.maritalStatus }} {{$t('resources.with')}}
										{{ employee.childrenNumber }} {{$t('resources.dependent_child')}}
									</p>
									<p class="text-5 text-second- mt-4 mb-1">
										Date de prise de service :
										{{ $filters.convertDate(employee.startDate, false) }}
									</p>
									<p class="text-5 text-second- mt-2 mb-1">
										Salaire actuel :
										{{
											employee.contracts.length > 0
												? $filters.formatAmount(
														employee.contracts[employee.contracts.length - 1]
															?.remuneration
												  )
												: 0
										}}
									</p>
								</div>

								<div v-show="!showInfo">
									<div
										class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
									>
										{{$t('resources.performances')}}
									</div>
									<div class="row py-4 px-3 g-3">
										<div class="col text-center">
											<h6 class="text-alt-">{{$t('resources.commitment')}}</h6>
											<HalfDonut
												:percent="employee.commitmentLevel"
												:radius="30"
												:height="30"
												:foreground="levelToColor(employee.commitmentLevel)"
											/>
										</div>
										<div class="col text-center">
											<h6 class="text-alt- mb-3 pb-1">{{$t('resources.pgs_rating')}}</h6>
											<p class="text-4" :title="`${employee.rate} ${$t('resources.star')}`">
												<template
													v-for="index in Math.round(employee.rate)"
													:key="index"
												>
													<i
														class="uil uil-star text-yellow-"
														:class="{
															'uil uil-star-half-alt':
																employee.rate >= index - 0.5 &&
																employee.rate < index,
														}"
													></i>
												</template>
											</p>
										</div>
										<div class="col text-center">
											<h6 class="text-alt- mb-3 pt-1">{{$t('resources.qualities')}}</h6>
											<p class="text-6">{{ employee.qualities }}</p>
										</div>
									</div>

									<div
										class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
									>
										{{$t('resources.summary')}}
									</div>
									<div class="text-second- p-4 text-justify text-5">
										{{ employee.observation }}
									</div>

									<div class="mt-2">
										<router-link
											:to="{
												name: 'employee-edit',
												params: { id: employee.id, slug: employee.slug },
											}"
										>
											<span class="button p-1 text-6 px-3">{{$t('resources.update')}}</span>
										</router-link>
									</div>
								</div>

								<div
									v-show="showInfo"
									class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
								>
									{{$t('resources.simplified_view')}}
								</div>
								<div
									v-show="showInfo"
									class="text-second- p-4 text-justify text-5"
								>
									{{ employee.lastname + ' ' + employee.firstname }}, demeurant
									à {{ employee.address }}, téléphone :
									{{ employee.phoneNumber }}, adresse e-mail :
									{{ employee.emailAddress }}; est né(e) le
									{{ $filters.convertDate(employee.birthDate) }} à
									{{ employee.birthPlace }} et est
									{{ employee.maritalStatus }} avec
									{{ employee.childrenNumber }} enfant(s) à charge. <br />
									<br />
									Gradé {{ employee.grade }} et immatriculé(e)
									{{ employee.matricule }}, il est {{ employee.job }} avec
									{{ employee.experienceYears }} an(s) d’expérience(s)
									accumulée(s) dans la société et
									{{ $filters.convertDate(employee.startDate) }} an(s) au poste
									actuel. Son salaire net de ce mois est de
									{{
										employee.contracts.length > 0
											? $filters.formatAmount(
													employee.contracts[employee.contracts.length - 1]
														?.remuneration
											  )
											: 0
									}}
									francs. <br />
									<br />
									Engagé(e) {{ employee.commitmentLevel }}/100 et noté(e)
									{{ employee.rate }} étoile(s), ses qualités se résument en
									{{ employee.qualities }}.
								</div>
							</div>
						</div>
						<div class="col-xl-6">
							<div class="hstack">
								<input type="month" v-model="month" v-bind:max="maxMonth" class="form-control w-25 mb-2 ms-auto">
							</div>

							<div class="border rounded mb-4">
								<div
									class="text-red- p-1 text-uppercase text-5 bg-light-hight- text-center"
								>
									{{$t('resources.payslip')}}
								</div>
								<div class="table-responsive">
									<table class="table text-6" style="width: 100% !important">
										<thead class="bg-primary-">
											<tr class="text-6 text-white-">
												<th class="text-white-">{{$t('resources.code')}}</th>
												<th class="text-white-">{{$t('resources.element')}}</th>
												<th class="text-white-">{{$t('resources.gain')}}</th>
												<th class="text-white-">{{$t('resources.detention')}}</th>
												<th class="text-white-">{{$t('resources.reminder')}}</th>
											</tr>
										</thead>
										<tbody>
											<tr
												class="text-6"
												v-for="(payslip, index) in payslips?.rows"
												:key="index"
											>
												<td>{{ payslip.code }}</td>
												<td>{{ payslip.element }}</td>
												<td>{{ payslip.gain }}</td>
												<td>{{ payslip.deduction }}</td>
												<td>{{ payslip.reminder }}</td>
											</tr>
										</tbody>
									</table>

									<div
										class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
									>
										{{$t('global.detail')}} 
									</div>
									<div class="text-second- p-4 text-justify text-5">
										<div class="row">
											<div class="col">
												<div class="row">
													<div
														class="col text-white bg-second- p-1 text-center d-flex justify-content-center align-items-center"
													>
														{{$t('resources.gain')}}
													</div>
													<div class="col border text-center p-1">
														{{ payslips[0]?.gain }}
													</div>
												</div>
											</div>
											<div class="col">
												<div class="row">
													<div
														class="col text-white bg-second- p-1 text-center d-flex justify-content-center align-items-center"
													>
														{{$t('resources.detention')}}
													</div>
													<div class="col border text-center p-1">
														{{ payslips?.deduction }}
													</div>
												</div>
											</div>
										</div>
										<div class="row mt-3">
											<div class="col">
												<div class="row">
													<div
														class="col text-white bg-second- p-1 text-center d-flex justify-content-center align-items-center"
													>
														{{$t('resources.reminder')}}
													</div>
													<div class="col border text-center p-1">
														{{ payslips?.reminder }}
													</div>
												</div>
											</div>
											<div class="col">
												<div class="row">
													<div class="col text-white bg-alt- p-1 text-center d-flex justify-content-center align-items-center">
														{{$t('resources.net_salary')}}
													</div>
													<div class="col border text-center p-1">
														{{ payslips?.salary }}
													</div>
												</div>
											</div>
										</div>
									</div>
									
									<div v-if="payslips?.rows == undefined" class="d-flex justify-content-end px-2">
										<button class="button bg-danger- text-white- fw-500 shadow-sm mt-1 mb-2"
											data-mdb-toggle="modal"
											data-mdb-target="#deleteModal" >
											{{ $t('resources.edit_file') }}
										</button>
									</div>
									<div v-else class="d-flex justify-content-end px-2">
										<button @click="downloadPayslip(true)" class="button btn-sm bg-green- text-white- fw-500 shadow-sm mt-1 mb-2">
											{{ $t('resources.show_file') }}
										</button>
										<button @click="downloadPayslip()" class="button btn-sm ms-2 bg-success- text-white- fw-500 shadow-sm mt-1 mb-2">
											{{ $t('resources.download_file') }}
										</button>
									</div>
								</div>
							</div>



							<div class="border rounded mb-4">
								<div
									class="text-red- p-1 text-uppercase text-5 bg-light-hight- text-center"
								>
									{{$t('resources.contracts')}}
								</div>

								<div v-if="!loading && employee.contracts.length != 0">
									<div class="table-responsive mt-0">
										<table
											class="table contracts align-middle table-hover table-striped mb-0"
											style="width: 100% !important"
										>
											<thead class="bg-primary-">
												<tr class="text-6 text-white">
													<th></th>
													<th>{{$t('global.type')}}</th>
													<th>{{$t('resources.start_date')}}</th>
													<th>{{$t('resources.end_date')}}</th>
													<th>{{$t('resources.remuneration')}}</th>
													<th>{{$t('resources.contract')}}</th>
												</tr>
											</thead>
											<tbody class="bg-alt-">
												<tr
													v-for="(contract, index) in employee.contracts"
													:key="contract.id"
													class="text-6 text-black"
												>
													<td>{{ index + 1 }}</td>
													<td>{{ contract.type }}</td>
													<td>
														{{ $filters.convertDate(contract.startDate) }}
													</td>
													<td>{{ $filters.convertDate(contract.endDate) }}</td>
													<td>
														{{ $filters.formatAmount(contract.remuneration) }}
													</td>
													<td class="text-center">
														<a
															@click="
																downloadFile(
																	contract.file ? contract.file.url : '',
																	'Contrat ' +
																		employee.lastname +
																		' ' +
																		employee.firstname
																)
															"
														>
															<i
																class="uil uil-arrow-circle-down fs-6 text-white"
																type="button"
															></i>
														</a>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div
									class="py-4 text-center text-black-"
									v-if="!loading && employee.contracts.length == 0"
								>
									{{$t('resources.no_contract_found')}}
								</div>
							</div>

							<div class="border rounded mb-4">
								<div
									class="text-red- p-1 text-uppercase text-5 bg-light-hight- text-center"
								>
									{{$t('resources.poste')}}
								</div>

								<div class="table-responsive mt-0">
									<table
										class="table jobs align-middle table-hover table-striped mb-0"
										style="width: 100% !important"
									>
										<thead class="bg-primary-">
											<tr class="text-6 text-white">
												<th>#</th>
												<th>{{$t('resources.designation')}}</th>
											</tr>
										</thead>
										<tbody class="bg-alt-">
											<tr
												v-for="(job, index) in employee.jobs"
												:key="job.id"
												class="text-6 text-black"
											>
												<td>{{ index + 1 }}</td>
												<td>
													<router-link
														:to="{
															name: 'postes-show',
															params: { id: job.id, slug: job.slug },
														}"
													>
														{{ job.designation }}
													</router-link>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<div class="border rounded mb-4">
								<div
									class="text-red- p-1 text-uppercase text-5 bg-light-hight- text-center"
								>
									{{$t('resources.sections')}}
								</div>

								<div class="table-responsive mt-0">
									<table
										class="table sections align-middle table-hover table-striped mb-0"
										style="width: 100% !important"
									>
										<thead class="bg-primary-">
											<tr class="text-6 text-white">
												<th>#</th>
												<th>Section</th>
											</tr>
										</thead>
										<tbody class="bg-alt-">
											<tr
												v-for="(section, index) in employee.sections"
												:key="section.id"
												class="text-6 text-black"
											>
												<td>{{ index + 1 }}</td>
												<td>
													<router-link
														:to="{
															name: 'sections-show',
															params: { id: section.id, slug: section.slug },
														}"
													>
														{{ section.name }}
													</router-link>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import InputFC from '@/components/InputFC.vue';
import LoadingMain from '@/components/LoadingMain.vue';
import HalfDonut from '@/components/HalfDonut.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';

export default {
	name: 'ShowEmployeePage',
	components: { Template, Back, LoadingMain, HalfDonut, InputFC, SpinnerMe },
	data() {
		return {
			showInfo: false,
			id: '',
			loading: true,
			employee: null,
			payslips: null,
			i: 0,

			isEdit: false,

			// varibles pay file
			gain: 0,
			retenue: "",
			rappel: "",
			salaireNet: "",
			message: "",
			month: "",

			nature: "",
			natures: [{
				id: "gain",
				name: "Gain"
			},{
				id: "deduction",
				name: "Deduction"
			},{
				id: "reminder",
				name: "Rappel"
			}],

			rows: [],
			showList: false,
			code: "",
			element: "",
			deduction: 0,
			reminder: 0,

			maxMonth: ""
			
		};
	},
	methods: {
		downloadFile(l, n) {
			Helper.downloadFile(l, n, this);
		},
		roundRate(rate) {
			return Math.round(rate);
		},
		levelToColor(level) {
			if (level < 50) {
				return '#F00';
			}

			if (level == 50) {
				return '#FF0';
			}

			if (level > 50) {
				return '#10B981';
			}
		},
		addPayslip() {
			if ((this.gain != "" || this.deduction != "" || this.reminder != "") && this.code != "" && this.element != "") {
				this.rows.push({
					gain: parseInt(this.gain),
					deduction: parseInt(this.deduction),
					reminder: parseInt(this.reminder),
					code: this.code,
					element: this.element
				})
				this.gain = 0
				this.deduction = 0
				this.reminder = 0
				this.code = ""
				this.element = ""
				this.nature = ""
				Helper.notification('success', "Ajouté avec succès");
			}
		},
		downloadPayslip(show = false) {
			this.loading = true
			let route = 'admin/employee/payslip/file?employee='+this.employee.id+'&date='+this.month+'-01';

			fetch(
					Helper.route(route),
					Helper.requestOptionsJSON()
				)
					.then((response) => {
						Helper.tokenChecker(response.status);						
						return response.blob();
					})
					.then(blob => {
						this.loading = false

						if (show) {
							const url = URL.createObjectURL(blob);

							window.open(url, '_blank');
						} else {
							const url = URL.createObjectURL(blob);

							const a = document.createElement('a');
							a.href = url;
							a.download = 'Fiche de paie '+this.employee.lastname+ ' ' +this.employee.firstname+'.pdf'; 
							document.body.appendChild(a);
							a.click();

							URL.revokeObjectURL(url);
							a.remove();	
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
		},
		savePayslip() {
			if (this.rows.length > 0) {
				this.ok = true;
				Helper.loading('submit', 'spinner', true);

				let body = {
					owner: this.employee.id,
					rows: this.rows,
					date: this.month+"-01"
				};
			

				fetch(
					Helper.route('admin/employee/payslip'),
					Helper.requestOptionsJSON('POST', body)
				)
					.then((response) => {
						Helper.tokenChecker(response.status);
						return response.json();
					})
					.then((data) => {
						console.log(data);
						Helper.loading('submit', 'spinner', false);

						if (data.problems) {
							this.errors.customer = Helper.getError(
								data.problems,
								'customer'
							);
							// this.errors.date = Helper.getError(data.problems, 'date');
						} else {
							if (!this.ok) {
								this.error_message = data.message;
								Helper.notification('error', data.message);
							} else {
								if (data.status) {
									this.message = data.message
									this.payslips = data.data
									this.rows = []
								} else {
									Helper.notification('error', data.message);
								}
							}
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit', 'spinner', false);
					});
				} else {
				Helper.notification('error', 'Veuillez renseigner le formulaire');
			}
		},
		getEmployee() {
			this.loading = true;
			fetch(Helper.route('admin/employee/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;

					
					if (data.data) {
						this.employee = data.data;
						this.payslips = data.data.payslips[0]
						this.month = this.payslips?.date

						if (this.payslips?.date) {
							const [month, year] = this.payslips?.date.split("/"); 
							this.month = `${year}-${month}`;
						}
					}
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		displayDo(percent) {
			// Get the canvas element and its context
			const canvas = document.getElementById('donutChart');
			const ctx = canvas.getContext('2d');

			// Function to draw a half donut chart
			function drawHalfDonut(percent) {
				// Clear the canvas
				ctx.clearRect(0, 0, canvas.width, canvas.height);

				// Set the circle properties
				const centerX = canvas.width / 2;
				const centerY = canvas.height;
				const radius = 40;
				const startAngle = Math.PI; // 180 degrees in radians
				const endAngle = startAngle + (percent / 100) * Math.PI;
				const counterClockwise = false;

				// Draw the outer circle
				ctx.beginPath();
				ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
				ctx.fillStyle = '#FFF';
				ctx.fill();

				// Draw the half donut
				ctx.beginPath();
				ctx.arc(
					centerX,
					centerY,
					radius,
					startAngle,
					endAngle,
					counterClockwise
				);
				ctx.lineTo(centerX, centerY);
				ctx.fillStyle = '#F00'; // You can change the color
				ctx.fill();
			}

			// Example: Draw a half donut with 75% completion
			drawHalfDonut(percent);
			this.i += 1;
		},
		getPayslip() {
			this.loading = true;
			fetch(Helper.route('admin/employee/payslip?employee=' + this.id+'&date='+this.month), Helper.requestOptions())
			.then((response) => {
				Helper.tokenChecker(response.status);
				if (response.status != 200) {
					this.ok = false;
				}
				return response.json();
			})
			.then((data) => {
				this.loading = false;
				console.log(data);
				
				if (data.data.length > 0) {
					this.payslips = data.data[0]
				} else {
					this.payslips = []
				}
			})
			.catch((error) => {
				this.loading = false;
				console.log(error);
			});
		}
	},
	created() {
		this.id = this.$route.params.id;
		this.getEmployee();

		const today = new Date();
		const year = today.getFullYear();
		const month = String(today.getMonth() + 1).padStart(2, '0');
		this.maxMonth = `${year}-${month}`;
	},
	watch : {
		month: function () {
			this.getPayslip();
		},
		nature: function() {
			if (this.nature == "gain") {
				this.reminder = 0
				this.deduction = 0
			} else if (this.nature == "deduction") {
				this.gain = 0
				this.reminder = 0
			} else {
				this.gain = 0
				this.deduction = 0
			}
		}
	}
};
</script>
<style>
canvas {
	display: block;
	margin: 20px auto;
	color: white;
}
</style>
