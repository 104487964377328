<template>
	<div>
		<Template title="null">
			<div class="bg-light-hight- hstack py-3 ps-2 pb-3">
				<!-- <router-link
					to="/documents"
					class="bg-light-hight- ms-2 text-6 text-black-"
					>{{ $t('tools.diary') }}</router-link
				> -->
				<router-link
					to="/formations"
					class="bg-light-hight- ms-2 text-6 text-black-"
					>{{ $t('tools.training') }}</router-link
				>
			</div>

			<section class="row px-4 m-0 g-4 mt-1">
				<!-- Modal -->
				<div
					class="modal fade"
					id="deleteModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-red-">
									{{ $t('global.deletion_warning') }}
								</h6>
								<hr />
								<p class="text-5" v-show="!deleted" v-html="$t('tools.deletion_warning_text')">
								
								</p>
								<p class="text-5 text-center" v-show="deleted">
									{{ $t('global.deleted_successfully') }}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
											@click="deleted = false"
										>
											{{ $t('global.close') }}
										</button>
									</div>
									<div class="col text-center" v-show="!deleted">
										<button
											type="button"
											class="bg-red- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
											@click="deletes(taskId)"
										>
											{{ $t('global.delete') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Modal -->
				<div
					class="modal fade"
					id="archiveModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="archiveModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-alt-">
									{{ $t('global.archiving_warning') }}
								</h6>
								<hr />
								<p class="text-5" v-show="!archived" v-html="$t('tools.archiving_warning_text')">
								
								</p>
								<p class="text-5 text-center" v-show="archived">
									{{ $t('global.archiving_successfully') }}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{ $t('global.close') }}
										</button>
									</div>
									<div class="col text-center" v-show="!archived">
										<button
											type="button"
											class="bg-alt- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
											@click="archived = true"
										>
											{{ $t('global.archive') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-9">
					<div
						class="bg-second- shadow-sm text-uppercase text-center rounded text-5 text-white px-4 p-1"
					>
						{{ $t('tools.recommandations') }}
					</div>
					<div class="row mt-1 g-2">
						<!-- Recent task -->
						<div v-for="task in recentTasks" :key="task.id" class="col-xl-3">
							<div class="rate_item bg-light-hight-1 rounded">
								<div class="vstack mx-auto p-3 py-1">
									<i class="uil uil-star text-center text-red- fs-1"></i>
									<h6 class="text-center text-5 fw-700 text-black-">
										{{ task.name }}
									</h6>
									<p class="text-color- text-5 text-center">{{ task.code }}</p>
								</div>
								<div class="bg-second- vstack px-2 rounded p-1">
									<span class="text-6 text-white- text-center">12/04/2022</span>
									<span class="text-light- mt-1 text-6 text-center"
										>Meeting Hall</span
									>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- help section -->
				<div class="col-xl-3">
					<div class="rounded bg-second- text-white text-4 text-center p-1">
						{{ $t('global.advice') }}
					</div>
					<div
						class="mt-2 bg-light-hight- text-alt- rounded text-7 text-center p-1"
						v-html="$t('tools.tools_advice_text')"
					></div>
				</div>

				<!-- Calendar view with task managment -->
				<div class="col-lg-12 mb-4">
					<div class="hstack mt-4">
						<router-link
							to="/calendar-new"
							class="bg-second- rounded ms-auto px-3 p-1"
						>
							<i class="uil uil-plus text-white"></i>
							<span class="ms-2 text-6 text-white">{{
								$t('tools.add_task')
							}}</span>
						</router-link>
						<router-link
							to="/formations/create"
							class="bg-second- rounded ms-3 px-3 p-1"
						>
							<i class="uil uil-plus text-white"></i>
							<span class="ms-2 text-6 text-white">{{
								$t('tools.add_training_now')
							}}</span>
					</router-link>
					</div>

					<div class="hstack mt-4 p-2 px-4 rounded bg-white">
						<div class="text-5 text-black-">
							{{ $t('tools.calendar') }}
						</div>
						<div class="hstack ms-auto">
							<label for="" class="text-6 me-2">{{ $t('tools.period') }}</label>
							<select
								class="form-control text-6"
								v-model="selectedMonth"
								@change="updateCalendar"
							>
								<option v-for="month in months" :key="month" :value="month">
									{{ month }}
								</option>
							</select>
						</div>
						<TaskSearchPopup
							v-show="showPopup"
							:date="searchDate"
							@close-modal="showPopup = false"
						></TaskSearchPopup>
						<div class="hstack ms-4">
							<label for="specificDate" class="text-6 me-2">{{
								$t('global.date')
							}}</label>
							<input
								type="date"
								id="specificDate"
								class="form-control text-6"
								v-model="searchDate"
							/>
							<button class="btn btn-primary ms-2" @click="showPopup = true">
								{{ $t('tools.search') }}
							</button>
						</div>

						<div class="ms-auto">
							<i class="uil uil-ellipsis-v fs-6"></i>
						</div>
					</div>

					<div class="shadow-1">
						<!-- <div class="row m-0 text-5 bg-primary-">
                            <div class="col border py-2" v-for="(date, index) in dates" :key="index">
                                <span class="text-white" v-text="date"></span>
                            </div>
                        </div> -->

						<!-- Afficher le calendrier pour le mois sélectionné -->
						<div class="row m-0 text-6">
							<div
								class="col-2 border bg-white- pb-3"
								v-for="(day, index) in calendar"
								:key="index"
								:class="{
									'bg-white-': isCurrentMonth(day),
									'bg-grey-': !isCurrentMonth(day),
								}"
							>
								<h6 class="text-end text-5 text-black- fw-600 my-3">
									{{ day.dayOfWeek }} {{ day.day }}
								</h6>

								<div v-if="hasTaskOnDate(day.date)">
									<TaskTag :taskData="day.date"></TaskTag>
									<FormationTag :trainingData="day.date"></FormationTag>
								</div>
								<div v-else>
									<div
										class="hstack cursor-pointer mt-1 mb-3"
										type="button"
										
									>
										<div
											class="me-2"
											style="width: 10px; height: 30px !important"
											:class="{
												'bg-grey-': isCurrentMonth(day),
												'bg-white-': !isCurrentMonth(day),
											}"
										></div>
										<router-link to="/calendar-new">
											<!-- <div class="hstack w-100 fw-500 text-6">
												<div>{{ $t('tools.free_day') }}</div>
											</div> -->
											<p class="text-7 mt-1 mb-0 text-black-">
												{{ formatTaskDay(day.date) }}
											</p>
											<p class="text-7 mt-1 mb-0 text-black-">
												{{ $t('tools.no_task') }}
											</p>
										</router-link>
									</div>
									<FormationTag :trainingData="day.date"></FormationTag>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<!-- <section>
                <div class="bg-white-">
                    
                </div>
            </section> -->
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import TaskTag from '@/components/TaskTag.vue';
import FormationTag from '@/components/FormationTag.vue';
import TaskSearchPopup from '@/components/TaskSearchPopup.vue';

import {
	startOfMonth,
	addMonths,
	format,
	eachDayOfInterval,
	subMonths,
} from 'date-fns';
import frLocale from 'date-fns/locale/fr';

export default {
	name: 'ToolsPage',
	components: { Template, TaskTag, FormationTag, TaskSearchPopup },
	data() {
		return {
			loading: false,
			deleted: false,
			taskId: null,
			searchDate: null,
			archived: false,
			showPopup: false,
			recentTasks: [],

			allTasks: [],
			allDaysTrainings: [],

			// dates: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
			selectedMonth: format(new Date(), 'MMMM yyyy'),
			months: [],
			calendar: [],
		};
	},

	methods: {
		updateCalendar() {
			this.calendar = this.generateCalendarForMonth(this.selectedMonth);
		},

		generateCalendarForMonth(selectedMonth) {
			const monthDate = startOfMonth(new Date(selectedMonth));

			const daysOfMonth = eachDayOfInterval({
				start: monthDate,
				end: addMonths(monthDate, 1),
			});

			return daysOfMonth.map((date) => ({
				date: date,
				day: format(date, 'd'),
				dayOfWeek:
					format(date, 'EEEE', { locale: frLocale }).charAt(0).toUpperCase() +
					format(date, 'EEEE', { locale: frLocale }).slice(1),
				month: format(date, 'MMMM'),
				year: format(date, 'yyyy'),
			}));
		},

		generateMonthRange(selectedDate) {
			const previousMonths = Array.from({ length: 10 }, (_, index) =>
				subMonths(selectedDate, index + 1)
			).reverse();

			const nextMonths = Array.from({ length: 10 }, (_, index) =>
				addMonths(selectedDate, index + 1)
			);

			const allMonths = [...previousMonths, selectedDate, ...nextMonths];

			this.months = allMonths.map((date) => format(date, 'MMMM yyyy'));
		},

		isCurrentMonth(date) {
			const currentDate = new Date();
			return date.month === format(currentDate, 'MMMM');
		},

		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toISOString().split('T')[0];
		},

		getDate(date) {
			const futureDate = new Date();
			futureDate.setTime(date);
			return futureDate;
		},

		getTime(date) {
			const myDate = this.getDate(date);
			if (!isNaN(myDate)) {
				return format(myDate, 'h:mm a');
			} else {
				return '';
			}
		},

		formatTaskDay(date) {
			return format(date, 'dd MMM yyyy', { locale: frLocale });
		},

		

		hasTaskOnDate(date) {
			return this.allTasks.some((task) =>
				this.isDateInRange(date, task.startDate, task.endDate)
			);
		},

		getTasksOnDate(date) {
			
			return this.allTasks.filter((task) =>
				this.isDateInRange(date, task.startDate, task.endDate)
			);
		},

		isDateInRange(date, startDate, endDate) {
			const formattedDate = this.formatDate(date);
			const formattedStartDate = this.formatDate(startDate);
			const formattedEndDate = this.formatDate(endDate);
			if(formattedDate >= formattedStartDate && formattedDate <= formattedEndDate){
				console.log('formatted Date',formattedDate);
				console.log('formatted Start Date',formattedStartDate);
				console.log('formatted End Date',formattedEndDate);
			}
			
			return (
				formattedDate >= formattedStartDate && formattedDate <= formattedEndDate
			);
		},

		getTaskColorClass(task) {
			if (task.status == 'valid') {
				return 'bg-alt-';
			} else {
				return 'bg-red-';
			}
		},

		loadList() {
			this.loading = true;
			fetch(Helper.route('admin/task/all?order=DESC'), Helper.requestOptions())
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					console.log('All Task List', data);
					if (data.data) {
						this.allTasks = data.data;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},

		loadRecentList() {
			this.loading = true;
			fetch(Helper.route('admin/task/recent'), Helper.requestOptions())
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					console.log('Recents Task List', data);
					this.recentTasks = data.data;
				})
				.catch((error) => {
					console.log(error);
				});
			},

		deleteRequest(id) {
			this.taskId = id;
		},

		deletes(taskId) {
			this.$isLoading(true);
			let body = {
				ids: [taskId],
			};
			fetch(
				Helper.route('admin/task/delete'),
				Helper.requestOptionsJSON('DELETE', body)
			)
				.then((response) => response.json())
				.then((data) => {
					this.$isLoading(false);
					this.deleted = true;
					this.allTasks = this.allTasks.filter((i) => i.id !== taskId);
					this.recentTasks = this.recentTasks.filter((i) => i.id !== taskId);
					console.log(data);
					// Helper.notification("success", data.message)
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
	},

	created() {
		this.loadList();
		this.loadRecentList();
		this.updateCalendar();
		this.generateMonthRange(new Date());
	},
};
</script>
<style>
.rate_item img {
	height: 70px;
	width: 70px;
	object-fit: cover;
}
.documents th,
.documents td {
	color: white !important;
}
.employe_item img {
	height: 100px;
	width: 100px;
}
</style>
