<template>
	<div>
		<Template :title="'Tarification'">
			<div class="row px-4 m-0">
				<LoadingMain :state="loading" />
			</div>
			<div class="row px-4 m-0 mt-4" v-if="!loading && plans.length != 0">
				<div class="row g-2 h-100">
					<div class="col-lg-2 h-100 col-md-12 mx-auto">
						<div class="bg-primary- h-100 p-4 rounded shadow-sm">
							<dl>
								<dt class="text-white- text-6 mt-4">{{$t('suscribe.features')}}</dt>
								<hr />
								<dd class="text-white- text-5 fw-600 mt-4">{{$t('suscribe.co_admin')}}</dd>
								<dd class="text-white- text-5 fw-600 mt-4">{{$t('suscribe.test_after_test')}}</dd>
								<dd class="text-white- text-5 fw-600 mt-4">
									{{$t('suscribe.brainstorming_test')}}
								</dd>
								<dd class="text-white- text-5 fw-600 mt-4">
									{{$t('suscribe.technical_assistance')}}
								</dd>
								<dd class="text-white- text-5 fw-600 mt-4">
									{{$t('suscribe.storage_espace')}}
								</dd>
								<hr />
								<dd class="text-white- text-5 fw-600 mt-4">
									{{$t('suscribe.monthly_subscription')}}
								</dd>
								<dd class="text-white- text-5 fw-600 mt-4">
									{{$t('suscribe.annual_subscription')}}
								</dd>
							</dl>
						</div>
					</div>
					<div
						class="col-lg-2 h-100 mx-auto col-md-6"
						v-for="plan in plans"
						:key="plan.id"
					>
						<div class="bg-light- h-100 rounded shadow border p-4">
							<dl>
								<dt class="text-black- text-6 text-center mt-4">
									{{ plan.name }}
								</dt>
								<hr />
								<dd
									class="text-black- text-6 text-center mt-4"
									v-for="pricing in plan.pricing"
									:key="pricing.id"
								>
									<span v-if="pricing.isBool == false">{{
										pricing.description
									}}</span>
									<span v-else>{{
										pricing.value == 1
											? $t('global.yes')
											: pricing.description == ''
											? $t('global.no')
											: pricing.description
									}}</span>
								</dd>
								<div v-if="plan.priceMonthly > 0">
									<hr />
									<dd class="text-black- text-6 text-center mt-4">
										{{ plan.priceMonthly }} F CFA/{{$t('global.month')}}
									</dd>
									<dd class="text-center mt-4">
										<button
											@click="suscribe(plan.id, plan.priceMonthly, 'monthly')"
											class="button px-2 py-1 text-5"
										>
											{{$t('suscribe.subscribe_monthly')}}
										</button>
									</dd>
									<hr />
									<dd class="text-black- text-6 text-center mt-4">
										{{ plan.priceAnnual }} F CFA/{{$t('suscribe.year')}} (25% {{$t('suscribe.reduction')}})
									</dd>
									<dd class="text-center mt-4">
										<button
											@click="suscribe(plan.id, plan.priceAnnual, 'annual')"
											class="button px-2 py-1 text-5"
										>
											{{$t('suscribe.subscribe_annualy')}}
										</button>
									</dd>
									<hr />
								</div>
								<div v-else class="error mt-4 text-6 text-center">
									<hr />
									{{$t('suscribe.have_this_default')}}
								</div>
							</dl>
						</div>
					</div>
				</div>
			</div>

			<div v-if="!loading2">
				<div class="table table-responsive px-5 my-5">
					<table class="table table-stripped shadow-sm table-bordered">
						<thead>
							<tr>
								<th class="text-4 fw-800 text-alt-">{{$t('suscribe.features')}}</th>
								<th class="text-4 fw-800 text-alt-">{{$t('suscribe.definition')}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="feature in features" :key="feature.id">
								<td class="text-5 text-alt- fw-800">{{ feature.name }}</td>
								<td>
									{{ feature.description }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</Template>
	</div>
</template>
<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
import { useUserStore } from '@/store/user';
import {
	openKkiapayWidget,
	addKkiapayListener,
	removeKkiapayListener,
} from 'kkiapay';

export default {
	name: 'PricingPage',
	components: { Template, LoadingMain },
	setup() {
		const store = useUserStore();
		return {
			store,
		};
	},
	data() {
		return {
			loading: true,
			loading2: true,
			plans: [],
			plan: '',
			features: [],
			type: '',
			transactionId: '',
			subscriptions: [],
			hasValidSubscription: false,
		};
	},
	methods: {
		loadList() {
			this.loading = true;
			fetch(Helper.route('public/pricings/plans'), Helper.requestOptions())
				.then((response) => response.json())
				.then((data) => {
					this.loading = false;
					console.log(data);
					this.plans = data.data;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		loadFeatures() {
			this.loading2 = true;
			fetch(Helper.route('public/pricings/features'), Helper.requestOptions())
				.then((response) => response.json())
				.then((data) => {
					this.loading2 = false;
					this.features = data.data;
				})
				.catch((error) => {
					this.loading2 = false;
					console.log(error);
				});
		},
		suscribe(id, amount, type) {
			if (this.store.user.isMainAdmin) {
				if (this.hasValidSubscription) {
					this.$swal({
						title: `${this.$t('global.attention')}!`,
						text: this.$t('suscribe.warning_text'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.$t('suscribe.yes_sure'),
						confirmButtonText: this.$t('suscribe.no_give_up'),
					}).then((result) => {
						if (!result.isConfirmed) {
							this.plan = id;
							this.type = type;
							openKkiapayWidget({
								amount: amount,
								api_key: Helper.KKIAPAY_KEY,
								sandbox: true,
							});
						}
					});
				} else {
					this.plan = id;
					this.type = type;
					openKkiapayWidget({
						amount: amount,
						api_key: Helper.KKIAPAY_KEY,
						sandbox: true,
					});
				}
			} else {
				this.$swal({
					title: 'Ooops!',
					text: this.$t('suscribe.cant_suscribe'),
					icon: 'info',
					confirmButtonText: this.$t('suscribe.allright'),
				});
			}
		},
		successHandler(response) {			
			if (response.transactionId) {
				this.transactionId = response.transactionId;
				this.suscription_add();
			}
		},
		getPreviousSubscriptions() {
			this.$isLoading(true);
			fetch(Helper.route('admin/subscription/all'), Helper.requestOptions())
				.then((response) => response.json())
				.then((data) => {
					this.$isLoading(false);
					if (data.data) {
						this.subscriptions = data.data.reverse();
						this.hasValidSubscription = !this.subscriptions.every(
							(s) => s.status !== 'valid'
						);
					}
					console.log(data);
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		suscription_add() {
			this.$isLoading(true);
			const body = {
				plan: this.plan,
				transactionId: this.transactionId,
				type: this.type,
			};

			console.log(body);
			

			fetch(
				Helper.route('admin/subscription/add'),
				Helper.requestOptionsJSON('POST', body)
			)
				.then((response) => response.json())
				.then((data) => {
					this.$isLoading(false);
					if (data.status) {
						Helper.notification('success', data.message);
						this.$router.push('/subscription');
					} else {
						Helper.notification('error', data.message);
					}
					console.log(data);
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
	},
	created() {
		this.loadList();
		this.loadFeatures();
		this.getPreviousSubscriptions();
	},
	mounted() {
		addKkiapayListener('success', this.successHandler);
	},
	beforeUnmount() {
		removeKkiapayListener('success', this.successHandler);
	},
};
</script>
<style></style>
