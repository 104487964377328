<template>
	<div>
		<Template sidebarMenu="offers">
			<section class="row px-4 m-0 g-4 mt-1">
				<Back />

				<!-- Info modal -->
				<!-- <div
					class="modal fade" :class="{'d-flex show': showOfferDetail}"
					id="infoModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="infoModalLabel"
					aria-hidden="true"
					>
					<div class="modal-lg modal-dialog modal-dialog-centered " @click.stop>
						<div class="modal-content rounded-0">
							<div class="modal-body" @click.stop>
								<h6 class="text-center text-red-">
									{{ $t('candidate.offers.offer_detail') }}
								</h6>
								<hr />
								<div class="" v-if="offer!=null">
									<div class="row align-items-center">
										<p class="col-4">{{$t('candidate.offers.task_to_complete')}}</p>
										<div v-html="offer.tasks" class="mt-2 border rounded col-8"></div>
									</div>
									<div class="row align-items-center mt-3">
										<p class="col-4">{{$t('candidate.offers.execution_place')}}</p>
										<div class=" col-8 border rounded ">	
											{{ offer.location }}
										</div>
									</div>
									<div class="row align-items-center mt-3">
										<p class="col-4">{{$t('candidate.offers.required_docs')}}</p>
										<div class=" col-8 border rounded " v-html="offer.requiredDocument">	
										
										</div>
									</div>
									<div class="row align-items-center mt-3">
										<p class="col-4">{{ $t('global.on_line') }}</p>
										<div class=" col-8 border rounded ">	
											{{ $filters.convertDate(offer.createdAt) }}
										</div>
									</div>
									<div class="row align-items-center mt-3">
										<p class="col-4">{{$t('global.contact')}}</p>
										<div class=" col-8 border rounded ">	
											{{ offer.contact }}
										</div>
									</div>
								</div>
								<div class="row mt-2">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
											@click="closeDetailModal"
										>
											{{$t('global.close')}}
										</button>
									</div>
									
								</div>
							</div>
						</div>
					</div>

					<div class="modal-backdrop fade show" style="z-index: -100;"></div>
				</div> -->




				<!-- Modal -->
				<div
					class="modal fade"
					id="deleteModal"
					data-mdb-backdrop="static"
					data-mdb-keyboard="false"
					tabindex="-1"
					aria-labelledby="deleteModalLabel"
					aria-hidden="true"
					>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content rounded-0">
							<div class="modal-body">
								<h6 class="text-center text-red-">
									{{$t('global.deletion_warning')}}
								</h6>
								<hr />
								<p class="text-5" v-show="!deleted" v-html="$t('candidate.offers.offer_deletion_warning')">
									
								</p>
								<p class="text-5 text-center" v-show="deleted">
									{{$t('global.deleted_successfully')}}
									<i class="uil uil-check-circle fs-4 text-primary-"></i>
								</p>
								<hr />
								<div class="row">
									<div class="col text-center">
										<button
											type="button"
											class="border bg-light px-3 text-5 rounded py-1"
											data-mdb-dismiss="modal"
										>
											{{$t('global.close')}}
										</button>
									</div>
									<div class="col text-center" v-show="!deleted">
										<button
											type="button"
											class="bg-red- text-white- px-3 rounded py-1 text-5 border-0 set-opacity"
											@click="set_status('deleted')"
										>
											{{$t('global.delete')}}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xl-9">
					<div
						class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
					>
						{{$t('global.previous')}}
					</div>
					<LoadingMain :state="loading" />

					<div
						v-if="offer == null && !loading"
						class="p-5 text-red- text-5 text-center"
					>
						{{$t('candidate.offers.offer_no_found')}}
					</div>

					<div class="my-4" v-if="offer !== null">
						<div class="border p-0">
							<div
								class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
							>
								{{$t('global.title').toUpperCase()}}
							</div>
							<p class="p-3 text-2 fw-700 m-0 text-center text-primary-">
								{{ offer.post }}
							</p>

							<div
								class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
							>
								{{$t('candidate.offers.characters')}}
							</div>
							<div class="px-5 m-0 py-3 text-5 mx-auto">
								<div class="row">
									<div class="col-5 pe-4">{{$t('global.profile')}}</div>
									<div class="col-7">
										<span class="me-5">:</span> {{ offer.profile }}
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-5 pe-4">Contrat</div>
									<div class="col-7">
										<span class="me-5">:</span> {{ offer.contract }}
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-5 pe-4">{{$t('candidate.offers.available_place')}}</div>
									<div class="col-7">
										<span class="me-5">:</span> {{ offer.placeNumber }}
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-5 pe-4">{{$t('candidate.offers.execution_place')}}</div>
									<div class="col-7">
										<span class="me-5">:</span> {{ offer.location }}
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-5 pe-4">{{$t('global.state')}}</div>
									<div class="col-7">
										<span class="me-5">:</span>
										{{ $filters.offerStatusFr(offer.status) }}
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-5 pe-4">{{$t('candidate.offers.apply_link')}}</div>
									<div class="col-7">
										<span class="me-5">:</span>
										<a
											:href="TEST_URL + 'offer/' + offer.id + '/' + offer.slug"
											target="_blank"
											class="text-red-"
											>{{
												TEST_URL + 'offer/' + offer.id + '/' + offer.slug
											}}</a
										>
										<i class="uil uil-copy fs-5 border py-1 px-3 bg-primary- text-white rounded ms-3" type="button" @click="copyToClipboard(TEST_URL + 'offer/' + offer.id + '/' + offer.slug)"></i>
									</div>
								</div>
							</div>

							<div
								class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
							>
								{{$t('candidate.offers.execution')}}
							</div>
							<div class="px-5 m-0 text-5 py-3 mx-auto">
								
								<div class="row mt-2">
									<div class="text-primary- text-5 mt-4">{{$t('candidate.offers.task_to_complete')}}</div>
									<div v-html="offer.tasks" class="mt-2 border pt-2"></div>
								</div>
								<div class="row mt-2">
									<div class="text-primary- text-5 mt-4">
										{{$t('candidate.offers.required_skills')}}
									</div>
									<div v-html="offer.skills" class="mt-2 border pt-2"></div>
								</div>
								<div class="row mt-2">
									<div class="text-primary- text-5 mt-4">{{$t('candidate.offers.required_docs')}}</div>
									<div
										v-html="offer.requiredDocument"
										class="mt-2 border pt-2"
									></div>
								</div>
								<div class="row mt-2">
									<div class="text-primary- text-5 mt-4">{{$t('candidate.offers.job_summary')}}</div>
									<div v-html="offer.summary" class="mt-2 border pt-2"></div>
								</div>
								<div class="row mt-2">
									<div class="text-primary- text-5 mt-4">{{$t('candidate.offers.abilities')}}</div>
									<div v-html="offer.aptitudes" class="mt-2 border pt-2"></div>
								</div>
							</div>

							<div
								class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
							>
								{{$t('global.dates')}}
							</div>
							<div class="px-5 m-0 text-5 py-3 mx-auto">
								<div class="row">
									<div class="col-5 pe-4">{{$t('global.on_line')}}</div>
									<div class="col-7">
										<span class="me-4">:</span
										>{{ $filters.convertDate(offer.createdAt) }}
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-5 pe-4">{{$t('global.fence')}}</div>
									<div class="col-7">
										<span class="me-4">:</span
										>{{ $filters.convertDate(offer.closingDate) }}
									</div>
								</div>
							</div>

							<div
								class="text-red- text-uppercase text-5 bg-light-hight- text-center p-1"
							>
								{{$t('candidate.offers.information')}}
							</div>
							<div class="px-5 text-5 m-0 py-3 mx-auto">
								<div class="row">
									<div class="col-5 pe-4">{{$t('global.contact')}}</div>
									<div class="col-7">
										<span class="me-4">:</span> {{ offer.contact }}
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-5 pe-4">{{$t('global.email')}}</div>
									<div class="col-7">
										<span class="me-4">:</span> {{ offer.email }}
									</div>
								</div>
							</div>

							<div class="bg-light-1 py-3 hstack">
								<router-link
									:to="{
										name: 'offer-edit',
										params: { id: offer.id, slug: offer.slug },
									}"
									v-if="offer.status != 'closed'"
									class="bg-primary- rounded ms-4 px-3 p-1"
								>
									<i class="uil uil-pen text-white"></i>
									<span class="ms-2 text-6 text-white">{{$t('global.update')}}</span>
								</router-link>
								<router-link
									:to="{
										name: 'offer-quiz',
										params: { id: offer.id, slug: offer.slug },
									}"
									class="bg-primary- rounded ms-4 px-3 p-1"
								>
									<i class="uil uil-question text-white"></i>
									<span class="ms-2 text-6 text-white">Quiz </span>
								</router-link>
								<router-link
									:to="{
										name: 'offer-applicants',
										params: { id: offer.id },
									}"
									class="bg-primary- rounded ms-4 px-3 p-1"
								>
									<i class="uil uil-user text-white"></i>
									<span class="ms-2 text-6 text-white">{{$t('candidate.offers.candidate_profile')}} </span>
								</router-link>
								<button
									v-if="offer.status != 'suspended' && offer.status == 'opened'"
									class="ms-4 button-default text-6 text-dark px-3 py-2 p-1 bg-light-"
									@click="set_status('suspended')"
								>
									{{$t('global.suspend')}}
								</button>
								<button
									v-if="offer.status != 'opened'"
									class="ms-4 button-default text-6 py-2 px-3 p-1 bg-green-"
									@click="set_status('opened')"
								>
									Ouvrir
								</button>
								<button
									v-if="offer.status != 'deleted' && offer.status != 'canceled'"
									class="ms-4 button-default text-6 px-3 py-2 p-1 bg-orange-"
									@click="set_status('canceled')"
								>
									{{$t('global.cancel')}}
								</button>
								<button
									v-if="offer.status != 'deleted'"
									class="ms-4 button-default text-6 px-3 py-2 p-1 bg-red-"
									data-mdb-toggle="modal"
									data-mdb-target="#deleteModal"
									@click="deleted = false"
									>
									{{$t('global.delete')}}
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Template>
	</div>
</template>
<style></style>
<script>
import Template from '@/components/Template.vue';
import Back from '@/components/Back.vue';
import Helper from '@/helpers/helper';
import LoadingMain from '@/components/LoadingMain.vue';
export default {
	name: 'OfferShowPage',
	components: { Template, Back, LoadingMain },
	data() {
		return {
			id: '',
			loading: true,
			offer: null,
			TEST_URL: Helper.TEST_URL,
			deleted: false,
			showOfferDetail: false
		};
	},
	mounted(){
		this.showOfferDetail = true
	},
	methods: {
		getOffer() {
			this.loading = true;
			fetch(Helper.route('admin/joboffer/' + this.id), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					if (data.data) {
						this.offer = data.data;
						console.log(this.offer);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		set_status(status) {
			if (
				!['opened', 'suspended', 'closed', 'canceled', 'deleted'].includes(
					status
				)
			) {
				return;
			}

			this.$isLoading(true);
			let body = {
				ids: [this.id],
				status: status,
			};
			fetch(
				Helper.route('admin/joboffer/manage'),
				Helper.requestOptionsJSON('PUT', body)
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.$isLoading(false);

					if (status == 'deleted') {
						this.deleted = false;
					}

					if (data.status) {
						this.offer.status = status;
					} else {
						Helper.notification('error', data.mesaage);
					}
				})
				.catch((error) => {
					this.$isLoading(false);
					console.log(error);
				});
		},
		closeDetailModal(){
			this.showOfferDetail = !this.showOfferDetail
		},
		copyToClipboard(text) {
            navigator.clipboard.writeText(text);
            Helper.notification("success", "Lien copié avec succès") 
        }
	},
	created() {
		this.id = this.$route.params.id;
		this.getOffer();
	},
};
</script>
<style></style>
