<template>
	<div>
		<Template :title="$t('admin.myRight')">
			<section class="p-5 py-4">
				<Back />

				<div class="row mt-2">
					<div class="col-xl-9">
						<div class="">
							<div class="bg-second- shadow-sm text-uppercase text-center rounded-pill text-5 text-white px-4 p-1"
							>
								{{$t('admin.myRight')}}
							</div>
							<div class="mt-4">
								<table
									class="table table-bordered table-striped"
									style="width: 100% !important"
								>
									<thead class="thead-dark">
										<tr>
											<th></th>
											<th
												class="text-center"
												v-for="(operation, index) in operations"
												:key="index"
											>
												{{ operation.text }}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(menu, index) in menus" :key="index">
											<td>{{ menu.text }}</td>
											<td
												class="text-center"
												v-for="(operation, i) in operations"
												:key="i"
											>
												<input
													class="cinput"
													style="cursor: not-allowed"
													:checked="findRigth(menu.key, operation.key)"
													disabled
													:id="`checkbox_${menu.key}_${operation.key}`"
													type="checkbox"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="col-xl-3">
						<div class="rounded bg-second- text-white text-4 text-center p-1">
							{{ $t('global.advice') }}
						</div>
						<div
							class="mt-1 bg-light-hight- text-alt- rounded text-7 text-center p-2"
							v-html="$t('global.advice_action')"
						></div>
					</div>
				</div>

				<div class="row d-flex justify-content-center"></div>
			</section>
		</Template>
	</div>
</template>

<script>
import Template from '@/components/Template.vue';
import Helper from '@/helpers/helper';
import Back from '@/components/Back.vue';

export default {
	components: { Template, Back },

	data() {
		return {
			coadmin: { rights: [] },
			menus: [],
			operations: [],
			newRights: [],
			rightsToAttrs: [],
		};
	},

	created() {
		this.getUser();
		this.getCoAdminMenus();
		this.getCoAdminOperations();
	},

	mounted: function () {
		for (const r of this.coadmin.rights) {
			this.newRights.push({
				menu: r.menu,
				operations: r.operations,
			});
		}
	},

	methods: {
		findRigth(menuKey, opKey) {
			var rights = [];

			const co = this.coadmin;

			rights = co.rights;

			var thisRight = rights.find((r) => r.menu.key == menuKey);

			if (thisRight) {
				if (thisRight.operations.find((o) => o.key == opKey)) {
					return true;
				}
			}

			return false;
		},

		getCoAdminMenus() {
			this.$isLoading(true);
			this.loading = true;
			fetch(
				Helper.route('admin/management/menu/all?sortBy=text&order=ASC'),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					this.$isLoading(false);
					if (data.data) {
						this.menus = data.data;
					}
					console.log(data);
				})
				.catch((error) => {
					this.loading = false;
					this.$isLoading(false);
					console.log(error);
				});
		},

		getCoAdminOperations() {
			this.$isLoading(true);
			this.loading = true;
			fetch(
				Helper.route('admin/management/operation/all?sortBy=id&order=ASC'),
				Helper.requestOptions()
			)
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.loading = false;
					this.$isLoading(false);
					if (data.data) {
						this.operations = data.data;
					}
					console.log(data);
				})
				.catch((error) => {
					this.loading = false;
					this.$isLoading(false);
					console.log(error);
				});
		},

		getUser() {
			fetch(Helper.route('admin/profile/get-data'), Helper.requestOptions())
				.then((response) => {
					Helper.tokenChecker(response.status);
					if (response.status != 200) {
						this.ok = false;
					}
					return response.json();
				})
				.then((data) => {
					this.coadmin = data.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>
