<template>
	<div>
		<div class="py-3 px-5 fs-5 fw-800 bg-white- text-primary-">
			PRO GESTION SOFT
		</div>
		<hr class="m-0" />
		<div
			class="py-4"
			style="background: rgba(0, 0, 0, 0.1) !important; height: calc(100vh - 12vh);">
			<div class="container h-100">
				<div class="row h-100">
					<div class="my-auto">
						<div class="row">
							<div class="col-md-6 text-center">
								<img src="@/assets/images/SANS_FOND_SIGNATURE.png" alt="" />
								<h4 class="text-center fw-600 text-three- mt-4">
									{{$t('auth.login_happy')}}
								</h4>
								<h2 class="text-primary- text-center text-uppercase fs-3 mt-4">
									{{$t('auth.login_manage')}} <br />
								</h2>
							</div>
							<div class="col-md-6">
								<div
									class="col-lg-8 rounded bg-primary-light- mx-auto border-black- shadow"
								>
									<div class="px-3 py-3 borderb-black-">
										<div class="hstack">
											<div class="vstack">
												<h4 class="fw-1000 text-2 mb-1 text-primary-" v-if="step === 1">
													{{$t('auth.new_signup')}}
												</h4>
												<h4 class="fw-1000 text-2 mb-1 text-primary-" v-if="step === 2 || step === 3">
													{{$t('auth.almost_there')}}
												</h4>
												<p class="text-6 my-0" v-if="step === 1">
													{{$t('auth.we_need_you')}}
												</p>
												<p class="text-6 my-0" v-if="step === 2">
													{{$t('auth.valid_email')}}
												</p>
												<p class="text-6 my-0" v-if="step === 3">
													{{$t('auth.email_is_verify')}}
												</p>
											</div>
											<i v-if="step !== 1"
												class="uil uil-times-circle ms-auto fs-5 mb-3 text-black"
												type="button"
												@click="$router.go(-1)"
											></i>
										</div>
									</div>

									<form
										@submit.prevent="register1"
										class="py-2 px-4"
										v-show="step === 1"
									>
										<InputMe
											v-model.trim="ifu"
											:error="errors.ifu"
											:placeholder="$t('auth.ifu_number')"
										/>
										<div class="row gx-2 mt-4">
											<InputMe
												class="col-8"
												v-model.trim="companyName"
												:error="errors.companyName"
												:placeholder="$t('auth.social_reason')"
											/>

											<InputMe
												:type="'select'"
												class="col-4"
												:options="juridicStatusList"
												v-model.number="juridicStatus"
												:error="errors.juridicStatus"
												:placeholder="$t('auth.legal_status')"
											/>
										</div>
										<InputMe
											:type="'email'"
											v-model.trim="emailAddress"
											:error="errors.emailAddress"
											:placeholder="$t('global.email')"
											class="mt-4"
										/>
										<SpinnerMe :elementId="'spinner1'" />
										<input
											type="submit"
											:value="$t('auth.verify_email')"
											id="submit1"
											class="button mt-4 w-100"
										/>
									</form>

									<form
										@submit.prevent="register2"
										class="py-3 px-4"
										v-show="step === 2"
									>
										<p class="text-center fw-600 text-5 text-three- mt-2">
											{{ message }}
										</p>
										<InputMe
											v-model.trim="code"
											:error="errors.code"
											:placeholder="$t('auth.verification_code')"
										/>
										<SpinnerMe :elementId="'spinner2'" />
										<input
											type="submit"
											:value="$t('auth.verify_code')"
											id="submit2"
											class="button mt-4 w-100"
										/>
									</form>

									<form
										@submit.prevent="register3"
										class="py-2 px-4"
										v-show="step === 3"
									>
										<InputMe
											v-model.trim="emailAddress"
											:disabled="true"
											:error="errors.emailAddress"
											:placeholder="$t('global.email')"
											:suffixIcon="'uil uil-check text-success text-1'"
										/>
										<div class="row mt-4">
											<InputMe
												class="col-6"
												:type="'tel'"
												v-model.trim="phoneNumber"
												:error="errors.phoneNumber"
												:placeholder="$t('global.phone')"
											/>

											<InputMe
												:type="'text'"
												class="col-6"
												v-model.trim="rccm"
												:error="errors.rccm"
												:placeholder="'RCCM'"
											/>
										</div>
										<InputMe
											:type="'toggle-password'"
											v-model.trim="password"
											:error="errors.password"
											:placeholder="$t('auth.pwd')"
											class="mt-4"
										/>
										<SpinnerMe :elementId="'spinner3'" />
										<input
											type="submit"
											:value="$t('auth.signup')"
											id="submit3"
											class="button mt-4 w-100"
										/>
									</form>

									<div class="p-3 text-center text-6 bordert-black-">
										{{$t('auth.when_connect')}}
										<a
											:href="SITE_URL + 'legal-notice'"
											target="_blank"
											class="red"
											>{{$t('auth.politicy')}}</a
										>, {{$t('auth.our')}}
										<a :href="SITE_URL + 'terms'" target="_blank" class="red"
											>{{$t('auth.conditions')}}</a
										>
										{{$t('auth.and_our')}}
										<a
											:href="SITE_URL + 'protection-policy'"
											target="_blank"
											class="red"
											>{{$t('auth.confidenciality')}}</a
										>.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Footer />
	</div>
</template>

<style>
img {
	height: 170px;
}
</style>

<script>
import Footer from '@/components/Footer.vue';
import Helper from '@/helpers/helper';
import InputMe from '@/components/InputMe.vue';
import SpinnerMe from '@/components/SpinnerMe.vue';
export default {
	name: 'RegisterPage',
	components: { Footer, InputMe, SpinnerMe },
	data() {
		return {
			ifu: '',
			companyName: '',
			juridicStatus: '',
			emailAddress: '',
			phoneNumber: '',
			rccm: '',
			code: '',
			password: '',
			errors: [],
			step: 1,
			message: '',
			SITE_URL: '',
			juridicStatusList: [],
		};
	},
	methods: {
		register1() {
			if (this.beforeRegister1()) {
				Helper.loading('submit1', 'spinner1', true);
				let registerFormData = new FormData();
				registerFormData.append('ifu', this.ifu);
				registerFormData.append('companyName', this.companyName);
				registerFormData.append('juridicStatus', this.juridicStatus);
				registerFormData.append('emailAddress', this.emailAddress);

				fetch(
					Helper.route('auth/signup/step-1'),
					Helper.requestOptions('POST', registerFormData)
				)
					.then((response) => response.json())
					.then((data) => {
						Helper.loading('submit1', 'spinner1', false);

						if (data.problems) {
							this.errors.ifu = Helper.getError(data.problems, 'ifu');
							this.errors.juridicStatus = Helper.getError(
								data.problems,
								'juridicStatus'
							);
							this.errors.emailAddress = Helper.getError(
								data.problems,
								'emailAddress'
							);
							this.errors.companyName = Helper.getError(
								data.problems,
								'companyName'
							);
						}
						if (data.status) {
							this.message = data.message;
							this.step = 2;
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit1', 'spinner1', false);
					});
			}
		},
		register2() {
			if (this.beforeRegister2()) {
				Helper.loading('submit2', 'spinner2', true);
				let registerFormData = new FormData();
				registerFormData.append('code', this.code);
				registerFormData.append('emailAddress', this.emailAddress);

				fetch(
					Helper.route('auth/signup/step-2'),
					Helper.requestOptions('POST', registerFormData)
				)
					.then((response) => response.json())
					.then((data) => {
						Helper.loading('submit2', 'spinner2', false);

						if (data.problems) {
							this.errors.code = Helper.getError(data.problems, 'code');
						}
						if (data.status) {
							this.step = 3;
						}
					})
					.catch((error) => {
						Helper.loading('submit2', 'spinner2', false);
						console.log(error);
					});
			}
		},
		register3() {
			if (this.beforeRegister3()) {
				Helper.loading('submit3', 'spinner3', true);
				let registerFormData = new FormData();
				registerFormData.append('rccm', this.rccm);
				registerFormData.append('password', this.password);
				registerFormData.append('phoneNumber', this.phoneNumber);
				registerFormData.append('emailAddress', this.emailAddress);

				fetch(
					Helper.route('auth/signup/step-3'),
					Helper.requestOptions('POST', registerFormData)
				)
					.then((response) => response.json())
					.then((data) => {
						Helper.loading('submit3', 'spinner3', false);

						if (data.problems) {
							this.errors.rccm = Helper.getError(data.problems, 'rccm');
							this.errors.phoneNumber = Helper.getError(
								data.problems,
								'phoneNumber'
							);
							this.errors.password = Helper.getError(data.problems, 'password');
						}
						if (data.status) {
							Helper.redirect(Helper.route('login', null, false));
						}
					})
					.catch((error) => {
						console.log(error);
						Helper.loading('submit3', 'spinner3', false);
					});
			}
		},
		beforeRegister1() {
			if (Helper.required(this.ifu) === '') {
				delete this.errors.ifu;
			} else {
				this.errors.ifu = Helper.required(this.ifu);
			}
			if (Helper.required(this.emailAddress) === '') {
				delete this.errors.emailAddress;
			} else {
				this.errors.emailAddress = Helper.required(this.emailAddress);
			}
			if (Helper.required(this.juridicStatus) === '') {
				delete this.errors.juridicStatus;
			} else {
				this.errors.juridicStatus = Helper.required(this.juridicStatus);
			}
			if (Helper.required(this.companyName) === '') {
				delete this.errors.companyName;
			} else {
				this.errors.companyName = Helper.required(this.companyName);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
		beforeRegister2() {
			if (Helper.required(this.code) === '') {
				delete this.errors.code;
			} else {
				this.errors.code = Helper.required(this.code);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
		beforeRegister3() {
			if (Helper.required(this.rccm) === '') {
				delete this.errors.rccm;
			} else {
				this.errors.rccm = Helper.required(this.rccm);
			}
			if (Helper.required(this.password) === '') {
				delete this.errors.password;
			} else {
				this.errors.password = Helper.required(this.password);
			}

			if (Helper.checkPassword(this.password) === '') {
				delete this.errors.password;
			} else {
				this.errors.password = Helper.checkPassword(this.password);
			}


			if (Helper.required(this.phoneNumber) === '') {
				delete this.errors.phoneNumber;
			} else {
				this.errors.phoneNumber = Helper.required(this.phoneNumber);
			}
			return Helper.objectNumberKey(this.errors) == 0 ? true : false;
		},
	},
	watch: {
		ifu: function () {
			if (Helper.required(this.ifu) === '') {
				delete this.errors.ifu;
			} else {
				this.errors.ifu = Helper.required(this.ifu);
			}
		},
		emailAddress: function () {
			if (Helper.required(this.emailAddress) === '') {
				delete this.errors.emailAddress;
			} else {
				this.errors.emailAddress = Helper.required(this.emailAddress);
			}
		},
		juridicStatus: function () {
			if (Helper.required(this.juridicStatus) === '') {
				delete this.errors.juridicStatus;
			} else {
				this.errors.juridicStatus = Helper.required(this.juridicStatus);
			}
		},
		companyName: function () {
			if (Helper.required(this.companyName) === '') {
				delete this.errors.companyName;
			} else {
				this.errors.companyName = Helper.required(this.companyName);
			}
		},
		code: function () {
			if (Helper.required(this.code) === '') {
				if (this.code.length === 6) {
					delete this.errors.code;
				} else {
					this.errors.code = 'Le code doit être 6 chiffres';
				}
			} else {
				this.errors.code = Helper.required(this.code);
			}
		},
		phoneNumber: function () {
			if (Helper.required(this.phoneNumber) === '') {
				delete this.errors.phoneNumber;
			} else {
				this.errors.phoneNumber = Helper.required(this.phoneNumber);
			}
		},
		password: function () {
			if (Helper.required(this.password) === '') {
				delete this.errors.password;
			} else {
				this.errors.password = Helper.required(this.password);
			}
		},
		rccm: function () {
			if (Helper.required(this.rccm) === '') {
				delete this.errors.rccm;
			} else {
				this.errors.rccm = Helper.required(this.rccm);
			}
		},
	},
	created() {
		this.SITE_URL = Helper.SITE_URL;
		fetch(Helper.route('juridicstatus/all'))
			.then((response) => response.json())
			.then((data) => {
				if (data.data) {
					data.data.forEach((status) => {
						this.juridicStatusList.push({
							id: status.id,
							name: status.code,
						});
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	},
};
</script>
